import {
  Component,
  OnInit,
  AfterViewInit,
  LOCALE_ID,
  ViewChild,
  ElementRef,
  Renderer,
  ChangeDetectorRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../../service/form.service';
import {UploadService} from '../../../service/upload.service';
import {
  FormModel,
  FormComment,
  WarningRecord,
  ErrorRecord,
  ReportChatComment
} from '../../../model/form.model';
import {DataService} from '../../../service/data.service';
import {Config} from '../../../config';
import {AppToastyService} from '../../../service/apptoasty.service';
import {FileUploader} from 'ng2-file-upload';
import {FANCYBOX_COMMON_OPTIONS} from '../../../model/common.model';
import {timer} from 'rxjs/internal/observable/timer';
import {CellValuePipe} from '../../../pipes/cell-value.pipe';

declare var $: JQueryStatic;

@Component({
  templateUrl: './form.component.html',
  providers: [AppToastyService, FormService, UploadService, DataService, Config, {
    provide: LOCALE_ID,
    useValue: 'ru-RU'
  }],
})
export class FormComponent implements OnInit, AfterViewInit {
  @ViewChild('loadLink') elLoadLink: ElementRef;

  // TODO: Добавить информацию о токене, иначе у нас идет загрузка без авторизации
  public uploader: FileUploader = new FileUploader(
    {
      autoUpload: true,
      method: 'POST',
      url: '/rest/file/upload'
    }
  );

  private draftUrl = '/personal-area/drafts';
  private branchUrl = '/personal-area/branch-form/';

  public id: number;
  public operatorId: number;
  public year: number;
  public quarter: number;
  public month: number;
  public summary: boolean;
  public refererDraft = false;
  public refererBranch = false;
  public refererList = true;

  public completionDate: Date;
  public stepNumber = 0;

  public userId: number;
  public userComment: string;

  public form: FormModel;
  public workingFormData = [];

  public signatureDialogOpened: boolean;
  public formComments: FormComment[] = [];

  public dispatcherErrorMessage: string;

  public periodCloseDate: Date;

  public fileUploadStep = 0;
  public formWasGot = false;

  refuseRequestPending: boolean = false;

  constructor(private toastyService: AppToastyService,
              private route: ActivatedRoute,
              private router: Router,
              private service: FormService,
              private renderer: Renderer,
              private uploadService: UploadService,
              private cdRef: ChangeDetectorRef,
              private cellValuePipe: CellValuePipe) {

    // :id/:year/:quarter/:month/:operatorId/:summary
    this.id = this.route.snapshot.params['id'];
    this.year = this.route.snapshot.params['year'];
    this.quarter = this.route.snapshot.params['quarter'];
    this.month = this.route.snapshot.params['month'];
    this.operatorId = this.route.snapshot.params['operatorId'];

    const summary_num = this.route.snapshot.params['summary'];

    this.summary = summary_num && summary_num === '1';

    this.form = new FormModel();

    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      const resp = JSON.parse(response);
      const message = resp.description;
      this.showErrorMessage(message);

      this.fileUploadStep = resp.code;
      this.form.reportId = resp.id;
    };

    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
      this.afterImportUniversalTemplate(response);
    };
  }

  isFirst(): boolean {
    return this.stepNumber === 0;
  }

  isSecond(): boolean {
    return this.stepNumber === 1;
  }

  isThird(): boolean {
    return this.stepNumber === 2;
  }

  setStep(step: number) {
    this.stepNumber = step;
  }

  formClass() {
    const classArray: string[] = ['bg_one_form'];

    if (this.form && ((this.form.status === 'error') || (this.form.status === 'rejected'))) {
      classArray.push('error');
    } else if (this.form && this.form.status === 'accepted') {
      classArray.push('adopted');
    }

    return classArray;
  }

  ngOnInit() {
    const referer = localStorage.getItem('previousRoute');

    this.refererDraft = referer && this.draftUrl === referer;

    this.refererBranch = referer && referer.indexOf(this.branchUrl) >= 0;

    this.refererList = !this.refererBranch && !this.refererDraft;

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.id) {
      this.userId = currentUser.id;
    }

    this.loadData();
  }

  private loadData() {
    this.service.getForm(
      this.userId,
      this.operatorId,
      this.id,
      this.year,
      this.quarter,
      this.month,
      this.form.correction,
      this.summary,
      false
    ).subscribe(
      getFormResult => {
        if (getFormResult.badnews != null) {
          const formName = getFormResult.formName ? getFormResult.formName : null;
          this.toastyService.addError('Запрос формы', (formName ? '\'' + getFormResult.formName + '\': ' : '') + getFormResult.badnews);
          this.router.navigate(['personal-area']);
          return;
        }

        this.loadForm(getFormResult);

        const source = timer(500);
        source.subscribe(() => {
          this.updateServiceChosen();
        });
      },
      error => {
        console.error(error);
        this.toastyService.addError('Запрос формы', 'Произошла ошибка во время запроса формы');
      }
    );

    this.service.getFormComment(this.id)
      .subscribe(
        result => {
          this.formComments = result;
        },
        error => {
          console.error(error);
        });
  }

  refreshWebUiPopover() {
    const source = timer(500);
    source.subscribe(() => {
      this.updateServiceChosen();
    });
  }

  updateServiceChosen() {
    this.formWasGot = true;
    $('.tltip').webuiPopover({
      closeable: true,
      placement: 'top',
      multi: true
    });
  }

  historyToggle(event) {
    const target = $(event.target);
    const tarParent = $(event.target).parent('li');
    const div = $(event.target).parent('li').find('.desc');

    $(div).next().slideToggle().end().toggleClass('open');
    $(div).parent().toggleClass('open');
  }

  ngAfterViewInit() {
    $('.fsf').click(function () {
      $('.blc_failure_surrender_form').slideDown(750);
      $('.btn_check, .btn_submit_form').prop('disabled', true).addClass('disabled');
      $(this).addClass('active');
      return false;
    });

    $('.history_list li .desc').click(function () {
      $(this).next().slideToggle().end().toggleClass('open');
      $(this).parent().toggleClass('open');
    });

    this.setIff();

    $('.closes').click(function () {
      $('.blc_failure_surrender_form').slideUp(750);
      $('.btn_check, .btn_submit_form').prop('disabled', false).removeClass('disabled');
      setTimeout(function () {
        $('.fsf').removeClass('active');
      }, 750);
    });

    this.setFancyBox();

    const jQuery: any = $;
    jQuery.scrollTo('#header');

    $('#go_top').hide();

    $(window).scroll(
      function () {
        if ($(this).scrollTop() > 100) {
          $('#go_top').fadeIn();
        } else {
          $('#go_top').fadeOut();
        }
      });
    $('#go_top a').click(function () {
      $('body,html').animate(
        {
          scrollTop: 0
        }, 800
      );
      return false;
    });
  }

  /**
   * При изменении значения ошибки/варнинга, меняем значение в соответствующей ячейке
   * @param err Ошибка/варнинг
   * @param value Новое значение
   */
  onChangeErrorOrWarningValue(err, value) {
    if (err.cellData) {
      for (const c of this.form.formData) {
        if (err.fieldCode === c.rowNumber + '.' + c.columnNumber) {
          c.value = value;
        }
      }
    } else {
      Object.keys(this.form.titulData).forEach(f => {
        if (f.toString() === err.fieldCode) {
          this.form.titulData[f] = value;
        }
      });
    }
  }

  /**
   * При изменении значения ячейки данных, будем менять значение и у варнингов и у ошибок
   * @param fieldCode Код ячейки (напр. "0100.03")
   * @param index
   * @param value Новое значение
   */
  onChangeCellValue(fieldCode: string, index: number, value) {
    const val = this.cleanValue(value);
    this.workingFormData[index].value = null;
    this.cdRef.detectChanges();
    this.workingFormData[index].value = val;
    this.cdRef.detectChanges();

    for (const e of this.form.errorData) {
      if (e.fieldCode === fieldCode) {
        e.value = val + '';
      }
    }

    for (const w of this.form.warningData) {
      if (w.fieldCode === fieldCode) {
        w.value = val + '';
      }
    }
  }

  getEndPeriod() {
    if (this.form.cumulative) {
      return this.form.endPeriodDateStr.replace(/\D+$/g, '');
    }

    return this.form.endPeriodDateStr;
  }

  transformCellValue(element, index: number) {
    element.target.value = this.cellValuePipe.transform(this.workingFormData[index].value);
  }

  parseCellValue(element, index: number) {
    const value = this.workingFormData[index].value;

    if (value === 0 || value === '0') {
      element.target.value = null;
      return;
    }

    element.target.value = value;
  }

  cleanValue(rawValue: string): string {
    if (!rawValue) {
      return '';
    }

    // Удаляем все, кроме цифр, точки и запятой
    // Меняем все точки на запятые
    // Удаляем повторяющиеся запятые на одиночные
    // Оставляем в строке только первую запятую
    const value = rawValue.replace(/[^0-9,.]/g, '')
      .replace(/[.]/g, ',')
      .replace(/[,]{2,}/g, ',')
      .replace(',', 'PLACEHOLDER')
      .replace(/,/g, '')
      .replace('PLACEHOLDER', ',');
    // Заменить последние 3 строки на эту, чтобы оставлять последнюю запятую
    // .replace(/,(?=.*?,)/g, '');

    if (!value || !/[0-9]/g.test(value)) {
      return '';
    }

    return value;
  }

  /**
   * При изменении значения в поле титульного листа, будем менять значение и у варнингов и у ошибок
   * @param name Наименование поля (напр. "okved")
   * @param value Новое значение
   */
  onChangeTitulForm(name: string, value) {

    for (const e of this.form.errorData) {
      if (e.fieldCode === name) {
        e.value = value;
      }
    }

    for (const w of this.form.warningData) {
      if (w.fieldCode === name) {
        w.value = value;
      }
    }
  }

  focusAndBlink(record: WarningRecord) {
    const element = document.getElementById(record.fieldCode);

    if (!element) {
      return;
    }

    if (record.cellData && this.isFirst()) {
      this.setStep(1);
    } else if (record.cellData && this.isSecond()) {

    } else {
      this.setStep(0);
    }

    const delay = timer(100);

    delay.subscribe(() => {
      this.renderer.invokeElementMethod(element, 'focus');
      this.renderer.invokeElementMethod(element, 'animate',
        [
          [
            {'opacity': 0, offset: 0.0},
            {'opacity': 1, offset: 0.25},
            {'opacity': 0, offset: 0.5},
            {'opacity': 1, offset: 1.0},
          ],
          {
            delay: 300,
            duration: 3000,
            iterations: 1,
            easing: 'linear',
            fill: 'both'
          }
        ]);
    });
  }

  isEmptyHistory() {
    return this.form.reportId == null;
  }

  getChatComment(comment: ReportChatComment) {
    let role: string;

    if (comment.userRole === 'ROLE_ADMIN') {
      role = 'Администратор';
    } else if (comment.userRole === 'ROLE_PROVIDER') {
      role = 'Пользователь';
    } else {
      role = '';
    }

    return comment.userName + '. Тел. ' + comment.userPhone + '. ' + role + '.';
  }

  onClearForm() {
    this.service.getForm(
      this.userId,
      this.operatorId,
      this.id,
      this.year,
      this.quarter,
      this.month,
      this.form.correction,
      this.summary,
      true
    ).subscribe(
      getFormResult => {
        this.loadForm(getFormResult);
        this.refreshWebUiPopover();
      },
      error => {
        console.error(error);
      }
    );

  }

  onRefuseForm() {
    this.refuseRequestPending = true;

    if ($('#refuseFlag').val() === '1') {
      this.form.refuseFlag = 1;
    }

    this.service.saveRefuse(this.form).subscribe(
      result => {
        this.refuseRequestPending = false;
        this.toastyService.addSuccess(
          'Отказ от заполнения',
          'Форма \'' + this.form.formName + '\' сохранена со статусом: отказ от заполнения'
        );
        this.loadForm(result);
        this.refreshWebUiPopover();
        this.updateIff();
      },
      error => {
        this.refuseRequestPending = false;
        console.error(error);
        this.toastyService.addError(
          'Отказ от заполнения',
          'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\''
        );
      }
    );
  }

  onRejectRefuseForm() {
    this.service.saveRejectRefuse(this.form).subscribe(
      result => {
        this.toastyService.addSuccess(
          'Отмена отказа от заполнения',
          'Форма \'' + this.form.formName + '\' сохранена со статусом: черновик'
        );
        this.loadForm(result);
        this.refreshWebUiPopover();
        this.updateIff();
      },
      error => {
        console.error(error);
        this.toastyService.addError(
          'Отмена отказа от заполнения',
          'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\''
        );
      }
    );
  }

  onSaveDraftForm() {
    const source = timer(500); // даем задержку
    source.subscribe(() => {

      this.parseValuesToFloat();

      this.service.saveDraft(this.form).subscribe(
        result => {
          if (!result.badnews) {
            this.toastyService.addSuccess('Сохранение черновика', 'Форма \'' + this.form.formName + '\' сохранена со статусом: черновик');
            this.loadForm(result);
            this.refreshWebUiPopover();

            this.setFancyBox();
          } else {
            this.toastyService.addError('Сохранение черновика', 'Форма \'' + this.form.formName + '\':' + result.badnews);
          }
        },
        error => {
          console.error(error);
          this.toastyService.addError('Сохранение черновика', 'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\'');
        }
      );
    });
  }

  onCheckForm() {
    this.parseValuesToFloat();

    this.service.saveDraft(this.form).subscribe(
      result => {
        if (!result.badnews) {
          this.toastyService.addSuccess('Сохранение', 'Форма \'' + this.form.formName + '\' сохранена');
          this.loadForm(result);
          this.checkOnly();

        } else {
          this.toastyService.addError('Проверка формы', 'Форма \'' + this.form.formName + '\':' + result.badnews);
        }
      },
      error => {
        console.error(error);
        this.toastyService.addError('Сохранение', 'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\'');
      }
    );
  }

  checkOnly() {
    this.service.checkForm(
      this.form.operatorId,
      this.form.reportId,
      this.userId,
      this.userComment,
      false
    ).subscribe(
      checkFormResult => {
        if (!checkFormResult.badnews) {
          this.loadForm(checkFormResult);
          if (!this.checkError(checkFormResult.errorData, checkFormResult.warningData)) {
            this.toastyService.addError('Проверка формы', 'Обнаружены ошибки/предупреждения');
            this.scrollToWarnings();
          } else {
            this.toastyService.addSuccess('Проверка формы', 'Форма \'' + this.form.formName + '\' проверена');
          }
        } else {
          this.toastyService.addError('Проверка формы', '\'' + this.form.formName + '\':' + checkFormResult.badnews);
        }

        this.refreshWebUiPopover();
      },
      error => {
        console.error(error);
        this.toastyService.addError('Проверка формы', 'Произошла ошибка во время проверки формы \'' + this.form.formName + '\'');
      }
    );
  }

  closeDialog() {
    const jQuery: any = $;
    jQuery.fancybox.close();
  }

  showSignPopup() {
    const jQuery: any = $;
    const dialog = jQuery.fancybox('#sign_popup', FANCYBOX_COMMON_OPTIONS);
  }

  validateForm(): boolean {
    if (this.formWasGot) {
      const titul_correct = $('#titul_form').hasClass('ng-invalid');
      const data_correct = $('#gl_form2').hasClass('ng-invalid');
      return !titul_correct && !data_correct;
    } else {
      return false;
    }
  }

  quickSaveCheck() {
    this.parseValuesToFloat();
    this.service.saveDraft(this.form).subscribe(
      result => {
        if (!result.badnews) {
          this.loadForm(result);
          this.checkOnly();
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  onSendForm() {
    if (this.validateForm()) {
      const jQuery: any = $;
      const dialog = jQuery.fancybox('#submitting_forms', FANCYBOX_COMMON_OPTIONS);
    } else {
      const jQuery: any = $;
      const dialog = jQuery.fancybox('#validation_warning', FANCYBOX_COMMON_OPTIONS);
      this.quickSaveCheck();
    }
  }

  editAccepted() {
    const jQuery: any = $;

    const dialog = jQuery.fancybox('#editing_shape_form', {
      fitToView: false,
      width: 'auto',
      height: 'auto',
      margin: [0, 0, 0, 0],
      autoSize: false,
      padding: [0, 0, 0, 0]
    });
  }

  letEditAccepted() {
    this.service.isCorrectionAllow(this.form.operatorId, this.form.reportId, this.userId)
      .subscribe(
        result => {

          const jQuery: any = $;
          this.closeDialog();

          if (result.allow) {

            this.form.reportId = null;
            this.form.state = 'DRAFT';
            this.form.correction = true;
            this.form.status = 'work_on';
            this.form.errorData = [];
            this.form.warningData = [];
            this.form.errors = false;

            this.onSaveDraftForm();

            jQuery.scrollTo('#header');

            return;
          }

          this.periodCloseDate = result.closeDate;
          const dialog = jQuery.fancybox('#delivered_after_closed_period_warning', FANCYBOX_COMMON_OPTIONS);
          return;
        },
        error => {
          console.error(error);
          this.toastyService.addError(
            'Запрос на редактирование формы',
            'Произошла ошибка во время проверки возможности редактирования формы'
          );
        }
      );
  }

  onSendFormWithSign() {
    const jQuery: any = $;
    jQuery.fancybox.close();

    const self = this;

    this.parseValuesToFloat();

    this.service.saveDraft(this.form).subscribe(
      saveFormResult => {
        this.toastyService.addSuccess('Сохранение', 'Форма \'' + this.form.formName + '\' сохранена');
        this.loadForm(saveFormResult);
        if (!this.validateForm()) {
          const jQuery: any = $;
          const dialog = jQuery.fancybox('#validation_warning', FANCYBOX_COMMON_OPTIONS);
        } else {

          this.service.checkForm(
            this.form.operatorId,
            this.form.reportId,
            this.userId,
            this.userComment,
            false
          ).subscribe(
            checkFormResult => {
              if (!checkFormResult.badnews) {
                this.loadForm(checkFormResult);

                if (!this.checkError(checkFormResult.errorData, checkFormResult.warningData)) {
                  this.toastyService.addError('Проверка формы', 'Обнаружены ошибки/предупреждения');
                  this.scrollToWarnings();
                } else {

                  this.toastyService.addSuccess('Проверка формы', 'Форма \'' + this.form.formName + '\' проверена');
                  const dialog = jQuery.fancybox('#submitting_forms_details', {
                    fitToView: false,
                    width: 'auto',
                    height: 'auto',
                    margin: [0, 0, 0, 0],
                    autoSize: false,
                    padding: [0, 0, 0, 0],
                    afterShow: function () {
                      self.signatureDialogOpened = true;
                    },
                    afterClose: function () {
                      self.signatureDialogOpened = false;
                      self.loadData();
                    }
                  });
                }
              } else {
                this.toastyService.addError('Проверка формы', '\'' + this.form.formName + '\':' + checkFormResult.badnews);
                this.scrollToWarnings();
              }
              this.refreshWebUiPopover();
            },
            error => {
              console.error(error);
              this.toastyService.addError('Проверка формы', 'Произошла ошибка во время проверки формы \'' + this.form.formName + '\'');
            }
          );
        }
      },
      error => {
        console.error(error);
        this.toastyService.addError('Сохранение', 'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\'');
      }
    );
  }

  onSendFormWithoutSign() {
    const jQuery: any = $;
    this.closeDialog();

    this.parseValuesToFloat();

    this.service.saveDraft(this.form).subscribe(
      saveFormResult => {
        if (!saveFormResult.badnews) {
          this.toastyService.addSuccess('Сохранение', 'Форма \'' + this.form.formName + '\' сохранена');
          this.loadForm(saveFormResult);

          if (!this.validateForm()) {
            const jQuery: any = $;
            const dialog = jQuery.fancybox('#validation_warning', FANCYBOX_COMMON_OPTIONS);
          } else {
            this.service.checkForm(
              this.form.operatorId,
              this.form.reportId,
              this.userId,
              this.userComment,
              false
            ).subscribe(
              checkFormResult => {
                if (!checkFormResult.badnews) {
                  this.loadForm(checkFormResult);
                  if (!this.checkError(checkFormResult.errorData, checkFormResult.warningData)) {
                    this.toastyService.addError('Проверка формы', 'Обнаружены ошибки/предупреждения');
                    this.scrollToWarnings();
                  } else {
                    this.toastyService.addSuccess('Проверка формы', 'Форма \'' + this.form.formName + '\' проверена');

                    // отправка start
                    this.service.acceptForm(
                      this.form.operatorId,
                      this.form.reportId,
                      this.userId,
                      this.userComment
                    ).subscribe(
                      acceptFormResult => {
                        this.loadForm(acceptFormResult);
                        if (!acceptFormResult.badnews) {
                          this.toastyService.addSuccess('Отправка формы', 'Форма \'' + this.form.formName + '\' отправлена');

                          const source = timer(500);
                          source.subscribe(() => {
                            this.updateServiceChosen();
                          });
                        } else {
                          this.toastyService.addError('Отправка формы', '\'' + this.form.formName + '\':' + acceptFormResult.badnews);
                          this.scrollToWarnings();
                        }
                      },
                      error => {
                        console.error(error);
                        this.toastyService.addError(
                          'Отправка формы',
                          'Произошла ошибка во время отправки формы \'' + this.form.formName + '\''
                        );
                      }
                    );
                    // отправка end
                  }
                } else {
                  this.toastyService.addError('Проверка формы', '\'' + this.form.formName + '\':' + checkFormResult.badnews);
                }
                this.refreshWebUiPopover();
              },
              error => {

                console.error(error);
                this.toastyService.addError('Проверка формы', 'Произошла ошибка во время проверки формы \'' + this.form.formName + '\'');
              }
            );
          }
        } else {
          this.toastyService.addError('Сохранение', 'Форма \'' + this.form.formName + '\':' + saveFormResult.badnews);
          try {
            this.loadForm(saveFormResult);
          } catch (e) {
          }
        }
      },
      error => {
        console.error(error);
        this.toastyService.addError('Сохранение', 'Произошла ошибка во время сохранения формы \'' + this.form.formName + '\'');
      }
    );

    jQuery.scrollTo('#header');
  }

  afterImportUniversalTemplate(response) {
    console.log('afterImportUniversalTemplate');
    console.log(response);
    console.log('OK');
    const parsed = JSON.parse(response);
    this.form.reportId = parsed.id;

    this.getForm();

    this.uploader.cancelAll();
    this.uploader.clearQueue();

    this.closeDialog();
  }

  continueUploadForm() {
    this.uploadService.continueUploadForm(this.form.operatorId, this.form.reportId).subscribe(
      result => {
        this.fileUploadStep = 0;
        this.dispatcherErrorMessage = null;
        this.closeDialog();
        this.getForm();
      },
      error => {
        const nope = 1;
      },
      () => {
      }
    );
  }

  private getForm() {
    this.service.getForm(
      this.userId,
      this.operatorId,
      this.id,
      this.year,
      this.quarter,
      this.month,
      this.form.correction,
      this.summary,
      false
    ).subscribe(
      result => {
        this.loadForm(result);
        const source = timer(500);
        if (result.badnews != null) {
          this.toastyService.addError('Запрос формы', '\'' + this.form.formName + '\': ' + result.badnews);
        }
        source.subscribe(() => {
          this.updateServiceChosen();
        });
      },
      error => {
        console.error(error);
        this.toastyService.addError('Запрос формы', 'Произошла ошибка во время запроса формы \'' + this.form.formName + '\'');
      }
    );
  }

  loadUniversalTemplate() {
    this.dispatcherErrorMessage = null;

    this.uploader.onBuildItemForm = (item, form) => {
      form.append('form', JSON.stringify(this.form));
    };

    const link: any = $(this.elLoadLink.nativeElement);

    this.uploader.cancelAll();
    this.uploader.clearQueue();
    link.val('');
    link.click();
  }

  private showErrorMessage(response) {
    console.log(response);
    this.dispatcherErrorMessage = response;
    this.uploader.cancelAll();
    this.uploader.clearQueue();
  }

  setIff() {
    $('#iff').click(function () {
      $(this).addClass('active');
      $('.blc_iff_desc').slideDown(750);
    });

    $('.blc_iff_ttl .closes, .form_filling').click(function () {
      $('.blc_iff_desc').slideUp(750);
      setTimeout(function () {
        $('#iff').removeClass('active');
      }, 750);
    });
  }

  updateIff() {
    const source = timer(1000);
    source.subscribe(() => {
      this.setIff();
    });
  }

  setFancyBox() {
    const jQuery: any = $;
    jQuery('.fancy_import').fancybox({
      fitToView: false,
      width: 'auto',
      height: 'auto',
      margin: [0, 0, 0, 0],
      autoSize: false,
      padding: [0, 0, 0, 0],
      tpl: {
        wrap:
          '<div class="fancybox-wrap fi" tabIndex="-1">' +
          ' <div class="fancybox-skin">' +
          '   <div class="fancybox-outer">' +
          '     <div class="fancybox-inner">' +
          '     </div>' +
          '   </div>' +
          ' </div>' +
          '</div>',
      }
    });
  }

  onNotifySignSuccess(newForm: FormModel) {
    console.log('onNotifySignSuccess');
    console.log(newForm);

    this.loadForm(newForm);
  }

  parseValuesToFloat() {
    const formData = this.workingFormData;
    for (let i = 0; i < formData.length; i++) {
      const strValue = (formData[i].value + '').replace(',', '.');
      let floatMayBe = parseFloat(strValue);
      if (isNaN(floatMayBe)) {
        floatMayBe = 0;
      }
      this.form.formData[i].value = floatMayBe;
    }
  }

  resetUploadDlg() {
    this.fileUploadStep = 0;
    this.dispatcherErrorMessage = '';
  }

  scrollToWarnings() {
    const source = timer(500);
    source.subscribe(() => {
      const jQuery: any = $;
      jQuery.scrollTo('.blc_form_error');
    });
  }

  checkError(errorData: ErrorRecord[], warningData: WarningRecord[]) {
    if (errorData.length !== 0 || warningData.length !== 0) {
      let result = true;
      for (let i = 0; i < errorData.length; i++) {
        result = result && (errorData[i].comment != null && errorData[i].comment.length > 0);
      }
      for (let i = 0; i < warningData.length; i++) {
        result = result && (warningData[i].comment != null && warningData[i].comment.length > 0);
      }
      return result;
    } else {
      return true;
    }
  }

  private loadForm(result: any) {
    this.form = result;
    this.workingFormData = [];

    for (let i = 0; i < result.formData.length; i++) {
      const cell = result.formData[i];
      this.workingFormData.splice(i, 0, {
        rowName: cell.rowName,
        columnName: cell.columnName,
        rowNumber: cell.rowNumber,
        columnNumber: cell.columnNumber,
        value: this.cleanValue(cell.value + ''),
        formattedValue: this.cellValuePipe.transform(cell.value),
        unitNotation: cell.unitNotation,
        note: cell.note
      });
    }
  }
}
