import { NgModule, LOCALE_ID } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { FileUploadModule } from 'ng2-file-upload';

import { AuthGuard } from './auth.guard';
import { routes } from './app.routes';

import { PreviousRouteRecorder } from './providers/PreviousRouteRecorder';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { HomeFormComponent } from './components/home-form/home-form.component';
import { AskControlComponent } from './components/ask-control/ask-control.component';
import { EventComponent } from './components/events/events.component';
import { FAQComponent } from './components/faq/faq.component';
import { StatisticComponent } from './components/statistics/statistics.component';
import { ContactComponent } from './components/contacts/contacts.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmComponent } from './components/confirm/confirm.component';

import { PersonalAreaMainComponent } from './components/personal-area/personal-area-main/personal-area-main.component';
import { FormsComponent } from './components/personal-area/forms/forms.component';
import { InstructionsFormsComponent } from './components/personal-area/instructions-forms/instructions-forms.component';
import { ProfileComponent } from './components/personal-area/profile/profile.component';
import { DraftFormsComponent } from './components/personal-area/draft-forms/draft-forms.component';
import { FormComponent } from './components/personal-area/form/form.component';
import { BranchFormListComponent } from './components/personal-area/branch-form-list/branch-form-list.component';
import { FormSignatureComponent } from './components/personal-area/form-signature/form-signature.component';

import { EmailValidator } from './validators/EmailValidator';
import { InnValidator } from './validators/InnValidator';
import { EqualValidator } from './validators/EqualValidator';

import { SwiperDirective } from './directives/swiper.directive';
import { PhoneMask } from './directives/phone-formatter.directive';
import { NumberMask } from './directives/number-formatter.directive';
import { EmailMask } from './directives/email-formatter.directive';

import { CellValuePipe } from './pipes/cell-value.pipe';
import { HttpClientModule } from '@angular/common/http';
import { RestClientService } from './service/rest-client.service';
import { Config } from './config';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginatorModule } from 'ngx-paginator';

import localeRu from '@angular/common/locales/ru'
import localeRuExtra from '@angular/common/locales/extra/ru'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FileInfoPipe} from "./pipes/file-info.pipe";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    FileUploadModule,
    PaginatorModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(routes),
    ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix'
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    RestClientService,
    CellValuePipe,
    FileInfoPipe,
    SafeHtmlPipe,
    Config,
    PreviousRouteRecorder,
    AuthGuard
  ],
  declarations: [
    AppComponent,
    PhoneMask,
    NumberMask,
    EmailMask,

    CellValuePipe,
    FileInfoPipe,
    SafeHtmlPipe,
    SwiperDirective,

    EmailValidator,
    EqualValidator,
    InnValidator,

    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HomeFormComponent,
    AskControlComponent,
    FAQComponent,
    StatisticComponent,
    ContactComponent,
    EventComponent,
    LoginComponent,
    ConfirmComponent,
    PersonalAreaMainComponent,
    FormsComponent,
    InstructionsFormsComponent,
    ProfileComponent,
    DraftFormsComponent,
    FormComponent,
    BranchFormListComponent,
    FormSignatureComponent
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {

}
