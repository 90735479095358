import {Component} from '@angular/core';
import {AuthenticationService} from '../../service/authentication.service';
import {Router} from '@angular/router';
import {Config} from '../../config';
import {DataService} from '../../service/data.service';

@Component({
  selector: 'app-menu-top',
  templateUrl: './header.component.html',
  providers: [AuthenticationService, Config, DataService]
})
export class HeaderComponent {
  public name: string;

  constructor(private router: Router, private auth: AuthenticationService) {
  }

  isLoggedIn() {
    const flag = this.auth.loggedIn();

    if (!flag && this.router.url && this.router.url.indexOf('/personal-area/') >= 0) {
      this.logout();
    }

    return flag;
  }

  logout() {
    this.auth.logout();

    this.router.navigate(['home']);
  }

  isActive(url): boolean {
    return this.router.isActive(url, false);
  }
}
