import { Routes } from '@angular/router';
import { PreviousRouteRecorder } from './providers/PreviousRouteRecorder';

import { AuthGuard } from './auth.guard';
import { HomeComponent } from './components/home/home.component';
import { FAQComponent } from './components/faq/faq.component';
import { StatisticComponent } from './components/statistics/statistics.component';
import { EventComponent } from './components/events/events.component';
import { ContactComponent } from './components/contacts/contacts.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { PersonalAreaMainComponent } from './components/personal-area/personal-area-main/personal-area-main.component';
import { FormsComponent } from './components/personal-area/forms/forms.component';
import { DraftFormsComponent } from './components/personal-area/draft-forms/draft-forms.component';
import { InstructionsFormsComponent } from './components/personal-area/instructions-forms/instructions-forms.component';
import { ProfileComponent } from './components/personal-area/profile/profile.component';
import { FormComponent } from './components/personal-area/form/form.component';
import { BranchFormListComponent } from './components/personal-area/branch-form-list/branch-form-list.component';

export const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'faq', component: FAQComponent},
  {path: 'faq/:id', component: FAQComponent},
  {path: 'statistics', component: StatisticComponent},
  {path: 'events', component: EventComponent},
  {path: 'contacts', component: ContactComponent},
  {path: 'login', component: LoginComponent},
  {path: 'confirm/:u/:s/:t', component: ConfirmComponent},
  {
    path: 'personal-area',
    component: PersonalAreaMainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'forms',
        pathMatch: 'full',
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'forms',
        component: FormsComponent,
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'drafts',
        component: DraftFormsComponent,
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'instructions',
        component: InstructionsFormsComponent,
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'form/:id/:year/:quarter/:month/:operatorId/:summary',
        component: FormComponent,
        canDeactivate: [PreviousRouteRecorder]
      },
      {
        path: 'branch-form/:id/:year/:quarter/:month',
        component: BranchFormListComponent,
        canDeactivate: [PreviousRouteRecorder]
      }
    ]
  }
];

