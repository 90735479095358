import { Component, OnInit, LOCALE_ID } from '@angular/core';
import {FormsService} from '../../../service/forms.service';
import {Config} from '../../../config';
import {DataService} from '../../../service/data.service';
import {OperatorForms, FormInfo, FormList, OperatorFormsYear, FormDateList} from '../../../model/formlist.model';
import {FANCYBOX_COMMON_OPTIONS} from '../../../model/common.model';
import {Router} from '@angular/router';
// import {AuthHttp, AuthConfig} from "angular2-jwt";

@Component({
  templateUrl: './forms.component.html',
  providers: [FormsService, DataService, Config, {provide: LOCALE_ID, useValue: 'ru-RU'}]
})
export class FormsComponent implements OnInit {

  // !!! Property 'showpreloader' is private and only accessible within class 'FormsComponent'.
  public showpreloader: number;
  private errorMessage: string;

  public operatorForms: OperatorForms;

  public operatorId: number;

  public activeForms: FormList[] = [];
  public activeYear: number;

  public warnInfo: FormInfo = new FormInfo();

  constructor(private formsService: FormsService, private router: Router) {
    this.operatorForms = new OperatorForms();
    this.showpreloader = 1;
  }

  acceptData(forms: OperatorForms) {
    this.operatorForms = forms;

    this.activeForms = [];

    const iLength = this.operatorForms.infos.length;

    if (this.operatorForms && this.operatorForms.infos && iLength > 0) {

      const currentForms = this.operatorForms.infos.find( i => i.year === this.activeYear);

      if (currentForms) {
        this.activeForms = currentForms.list;
        return;
      }

      this.activeYear = this.operatorForms.infos[iLength].year;
      this.activeForms = this.operatorForms.infos[iLength].list;
    }
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // console.log('FormsComponent');
    console.log(currentUser);

    this.setActiveYear();

    if (currentUser && currentUser.operatorId) {
      this.operatorId = currentUser.operatorId;

      this.formsService.getForms(currentUser.id)
        .subscribe(
          data => {
            this.acceptData(data);
            this.showpreloader = 0;
            if (this.operatorId !== data.operatorId) {
              this.operatorId = data.operatorId;
              currentUser.operatorId = data.operatorId;
              localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
          }
          , error => {
            this.errorMessage = <any>error;
          }
        );
    } else {
      console.error('Operator ID not found');
    }
  }

    setActiveYear() {
        this.formsService.getActiveYear()
            .subscribe(
                data => {
                    this.activeYear = data;
                }
            );
    }


  getItemClass(info: FormInfo) {
    const classArray: string[] = [];

    const stringStatus: string = info.status + '';

    if (stringStatus === 'error') {
      classArray.push('error_item');
    } else if (stringStatus === 'accepted') {
      classArray.push('adopted_item');
    } else if (stringStatus === 'refused') {
      classArray.push('renouncement_item');
    }

    return classArray;
  }


  getListClass(dateList: FormDateList, list: FormList) {
    const classArray: string[] = ['clearfix', 'global_table'];

    if (dateList.allFail) {
      classArray.push('error');
    } else if (dateList.allAccepted) {
      classArray.push('adopted');
    } else if (dateList.containmentFail) {
      // classArray.push('error_item');
    }

    if (list && list.completePeriod) {
      classArray.push('empty_field');
    }

    return classArray;
  }

  getYearClass(info: OperatorFormsYear) {
    const yearClass: string[] = [];

    if (info.year === this.activeYear) {
      yearClass.push('active');
    }

    const stringStatus: string = info.status + '';

    if (stringStatus === 'success') {
      yearClass.push('status_good');
    } else if (stringStatus === 'error') {
      yearClass.push('status_error');
    } else {
      // Пусто
    }

    return yearClass;
  }

  yearClicked(year) {
    this.activeYear = year;
    this.activeForms = [];

    for (let i = 0; i < this.operatorForms.infos.length; i++) {
      if (this.operatorForms.infos[i].year === year) {
        this.activeForms = this.operatorForms.infos[i].list;
      }
    }
  }

  openPeriodWarning(info: FormInfo) {
    this.warnInfo = info;

    const jQuery: any = $;
    const dialog = jQuery.fancybox('#period_warning', FANCYBOX_COMMON_OPTIONS);
  }

  closeWarning() {
    const jQuery: any = $;
    jQuery.fancybox.close();
  }

  viewFutureForm() {
    const jQuery: any = $;

    jQuery.fancybox.close();

    /*
     <p *ngIf="!operatorForms.composite && formInfo.completePeriod">
      <a [routerLink]="['../form', formInfo.id, formInfo.year, formInfo.quarter, formInfo.month, operatorId, '0']"
        class="link_form">{{formInfo.shortName}}
      </a>
     </p>
     <p *ngIf="operatorForms.composite && formInfo.completePeriod">
      <a [routerLink]="['../branch-form', formInfo.id, formInfo.year, formInfo.quarter, formInfo.month]"
        class="link_form">{{formInfo.shortName}}
      </a>
     </p>
     */
    if (this.operatorForms.composite) {
      this.router.navigate([
        '/personal-area/branch-form',
          this.warnInfo.id,
          this.warnInfo.year,
          this.warnInfo.quarter,
          this.warnInfo.month
        ]);
    } else {
      this.router.navigate([
        '/personal-area/form',
        this.warnInfo.id,
        this.warnInfo.year,
        this.warnInfo.quarter,
        this.warnInfo.month,
        this.operatorId,
        '0'
      ]);
    }
  }
}
