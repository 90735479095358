import { Injectable } from '@angular/core';
import { Config } from '../config';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AppToastyService {
  constructor(private config: Config, private toastyService: ToastrService) {

  }

  addSuccess(title: string, message: string) {
    this.toastyService.success(
      message,
      title,
      {
        closeButton: true,
        timeOut: this.config.TOAST_TIMEOUT
      });
  }

  addInfo(title: string, message: string) {
    this.toastyService.info(
      message,
      title,
      {
        closeButton: true,
        timeOut: this.config.TOAST_TIMEOUT
      });
  }

  addError(title: string, message: string) {
    this.toastyService.error(
      message,
      title,
      {
        closeButton: true,
        timeOut: this.config.TOAST_TIMEOUT
      });
  }

  addWarning(title: string, message: string) {
    this.toastyService.warning(
      message,
      title,
      {
        closeButton: true,
        timeOut: this.config.TOAST_TIMEOUT
      });
  }
}
