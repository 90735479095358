import {Directive, OnInit, OnChanges, DoCheck, SimpleChanges, Renderer, ElementRef} from "@angular/core";

declare var $: JQueryStatic;

//не используется
@Directive({
  selector: '[swiper]'
})

export class SwiperDirective implements OnInit, OnChanges, DoCheck {
  _swiper: any;

  constructor(private el: ElementRef, private renderer: Renderer) {
  }

  ngOnInit(): void {
    // this._swiper = new Swiper($(this.el.nativeElement), {
    //   loop: true,
    //   autoplay: 0,
    //   speed: 4200,
    //   pagination: '.swiper-pagination',
    //   slidesPerView: 1,
    //   paginationClickable: true,
    //   spaceBetween: 0,
    //   preventClicks: false,
    //   paginationType: 'fraction'
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngDoCheck(): void {
  }
}
