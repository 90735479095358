import {
  LOCALE_ID,
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChange,
  ElementRef,
  ViewChild, Output, EventEmitter
} from '@angular/core';
import {Config} from '../../../config';
import {DataService} from '../../../service/data.service';
import {FormService} from '../../../service/form.service';
import {AppToastyService} from '../../../service/apptoasty.service';
import {FormModel, CertificateRef, SignerInfo, SignData} from '../../../model/form.model'; // ResultMessage

declare var $: JQueryStatic;

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './form-signature.component.html',
  providers: [AppToastyService, FormService, DataService, Config, {provide: LOCALE_ID, useValue: 'ru-RU'}]
})
export class FormSignatureComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() form: FormModel;
  @Input() user: number;
  @Input() opened: boolean;
  @Input() userComment: string;

  @Output() notify: EventEmitter<FormModel> = new EventEmitter<FormModel>();

  @ViewChild('certificateList')
  certificateList: ElementRef;

  public oStore: any;

  public sigMessage: string;

  public errMessage: string;

  public errPluginMessage: string;

  public pluginFail: boolean;

  public items: CertificateRef[] = [];

  public state: string;

  public selectedCert: any;

  public imageStamp: string;

  public niceScrollInit = false;

  constructor(private toastyService: AppToastyService, private service: FormService) {
    this.form = new FormModel();
    this.state = 'plugin_init';
  }

  canSend(state) {
    return state === 'success' && state !== 'plugin_err';
  }

  canFormSig(state) {
    return state !== 'success' && state !== 'plugin_err';
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    const log: string[] = [];

    for (const propName in changes) {
      if (propName === 'opened') {
        const changedProp = changes[propName];
        const from = JSON.stringify(changedProp.previousValue);
        const to = JSON.stringify(changedProp.currentValue);

        if (this.opened) {
          this.initPlugin();
        } else {
          this.clear();
        }
      }
    }
  }

  pluginInitFail() {
    this.pluginFail = true;
    this.state = 'plugin_err';
    this.errPluginMessage = 'В вашем браузере функционал подписи не доступен. Пожалуйста, обратитесь к администратору.';
  }

  ngOnInit(): void {
    // include_async_code2('/');
    // async_resolve();
    this.pluginFail = false;
    this.errPluginMessage = null;

    try {
      const enable = isPluginEnable();

      if (enable) {
        if (isAsync()) {
          this.loadAsyncScript();
        }
      } else {
        console.log('Sign plugin not enabled');
        this.pluginInitFail();
      }
      // let s = new Promise(
      //         (resolve) => {
      //             this.loadAsyncScript();
      //         }
      //     );
    } catch (error) {
      console.log('Sign plugin error initialization');
      console.log(error);
      this.pluginInitFail();
    }
  }

  loadAsyncScript() {
    console.log('preparing to load...');
    const node = document.createElement('script');
    node.src = '/assets/js/sig/async_code.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngAfterViewInit(): void {
    // console.log('FormSignatureComponent - ngAfterViewInit');
  }

  initCertListElement(list) {
    this.items = list;

    const selectForm: any = $(this.certificateList.nativeElement);
    const chosen_params = {
      'disable_search': true, // поле поиска выключено
      width: '100%',
      touchbehavior: false
    };
    selectForm.chosen(chosen_params);
    selectForm.chosen().change(this, (obj, value) => {
      this.selectedCert = value.selected;
      this.errMessage = null;
    });

    // TODO: Костыль, заставляющий chosen подхватить изменения DOM.
    // TODO: И вопрос - почему jQuery плагин не обернут в ангуляровский компонент? Тогда таких вещей делать бы не пришлось.
    // TODO: Есть время, займись.
    setTimeout(() => {
      $(this.certificateList.nativeElement).trigger('chosen:updated');

      if (this.items.length > 0) {
        this.state = 'cer_select';
      }
    }, 10);

    this.niceScrollInit = true;

    if (!this.niceScrollInit) {
      // let div : any = $("#submitting_forms_details .popups_desc_in");
      //
      // div.niceScroll({
      //     cursorcolor:"#18338b",
      //     touchbehavior: false,
      //     autohidemode:false,
      //     background:"#cccccc",
      //     cursorwidth:"8px",
      //     cursorborder: "1px",
      //     cursorfixedheight: 39,
      //     zindex: 560,
      //     railpadding: ({top:0,right:0,left:0,bottom:0}),
      //     railoffset: true,
      // });
      //
      // $(".nicescroll-rails").appendTo(div);
    }
  }

  initCertList(list) {
    const listCerts: CertificateRef[] = [];

    list.forEach(
      element => {
        listCerts.push(new CertificateRef(element.text, element.title, element.value));
      }
    );

    if (listCerts && listCerts.length > 0) {
      this.initCertListElement(listCerts);
    }
  }

  initCertListError(error) {
    this.pluginInitFail();
  }

  initPlugin() {

    console.log('initPlugin');
    console.log(this.pluginFail);

    if (this.pluginFail) {
      return;
    }

    this.errMessage = null;
    this.state = 'plugin_init';

    if (isAsync()) {
      console.log('isAsync');

      getListCertificates_Async(this);
    } else {
      try {
        this.oStore = cadesplugin.CreateObject('CAdESCOM.Store');
        this.oStore.Open();
      } catch (ex) {
        console.log('Ошибка при открытии хранилища: ' + ex);

        this.pluginInitFail();

        return;
      }

      let certCnt: number;

      try {
        certCnt = this.oStore.Certificates.Count;

        if (certCnt === 0) {
          throw new Error('Cannot find object or property. (0x80092004)');
        }
      } catch (ex) {
        const message = this.getErrorMessage(ex);

        console.log(ex);

        if ('Cannot find object or property. (0x80092004)' === message ||
          'oStore.Certificates is undefined' === message ||
          'Объект или свойство не найдено. (0x80092004)' === message) {
          this.oStore.Close();

          this.errMessage = 'Ошибка получения списка сертификатов';

          return;
        }
      }

      for (let i = 1; i <= certCnt; i++) {
        let cert: any;
        try {
          cert = this.oStore.Certificates.Item(i);
        } catch (ex) {
          console.log('Ошибка при перечислении сертификатов: ' + this.getErrorMessage(ex));

          this.errMessage = 'Ошибка чтения сертификата';

          return;
        }

        const dateObj = new Date();

        try {
          if (dateObj < cert.ValidToDate && cert.HasPrivateKey() && cert.IsValid().Result) {
            const certObj = new CertificateObj(cert);
            this.items.push(new CertificateRef(certObj.GetCertThumbprint(), certObj.GetFullName(), cert.Thumbprint));
          } else {
            continue;
          }
        } catch (ex) {
          console.log('Ошибка: ' + this.getErrorMessage(ex));
          this.errMessage = 'Ошибка: ' + this.getErrorMessage(ex);
        }
      }
    }

    this.initCertListElement(this.items);

  }

  clear() {
    this.errPluginMessage = null;
    this.errMessage = null;
    this.state = 'plugin_init';
    this.selectedCert = null;
    this.items = [];

    if (!this.pluginFail) {
      try {
        if (this.oStore) {
          this.oStore.Close();
        }
      } catch (ex) {
        console.log('Ошибка при закрытии хранилища: ' + ex);
      }
    }

    this.pluginFail = false;
  }

  close() {
    const jQuery: any = $;
    jQuery.fancybox.close();
  }

  findCertificate(thumbprint: string): any {
    const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
    const oCerts = this.oStore.Certificates.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint);

    if (oCerts.Count === 0) {
      alert('Certificate not found');
      return;
    }
    const oCert = oCerts.Item(1);
    return oCert;
  }

  saveFormSign(signature, reportId) {
    const data: SignData = new SignData();

    // data.signer = undefined;
    data.sign = signature;
    data.reportId = reportId;

    this.service.saveSign(data).subscribe(
      result => {
        if (result.success) {
          console.log('.nicescroll-rails removing');
          console.log($('#submitting_forms_details .nicescroll-rails'));

          const div: any = $('#submitting_forms_details .popups_desc_in');
          div.getNiceScroll().hide();

          this.state = 'success';
          this.imageStamp = result.description;
        } else {
          this.errMessage = 'Ошибка сохранения подписи: ' + result.description;
        }
      },
      error => {
        console.error(error);
        this.errMessage = 'ошибка сохранения подписи';
      }
    );

  }

  signAsyncCallbackSave(signature) {
    this.saveFormSign(signature, this.form.reportId);
  }

  signAsyncCallbackErr(err) {
    console.log(err);
  }

  createSig() {

    console.log('createSig');

    if (this.selectedCert == null) {
      const value = $(this.certificateList.nativeElement).chosen().val();

      console.log(value);

      if (value != null && value.length > 0) {
        this.selectedCert = value;
      } else {
        this.errMessage = 'Не выбран сертификат';
      }

      return;
    }

    const dataToSign = JSON.stringify(this.form);

    if (isAsync()) {
      return MakeCadesBesSign_Async(this.selectedCert, dataToSign, this);
    } else {
      try {
        // FillCertInfo_NPAPI(certificate);
        const certificate = this.findCertificate(this.selectedCert);
        const info: SignerInfo = new SignerInfo();
        const certObj = new CertificateObj(certificate);

        // Владелец:" +
        info.user = certObj.GetCertName();
        // Издатель:" +
        info.issuer = certObj.GetIssuer();
        // Выдан: <b>" + certificate.GetCertFromDate()
        info.fromDate = certObj.GetCertFromDate();
        // Действителен до: <b>" + certificate.GetCertTillDate()
        info.tillDate = certObj.GetCertTillDate();
        // Криптопровайдер: <b>" + certificate.GetPrivateKeyProviderName()
        info.keyProviderName = certObj.GetPrivateKeyProviderName();
        // Алгоритм ключа: <b>" + certificate.GetPubKeyAlgorithm()
        info.pubKeyAlgorithm = certObj.GetPubKeyAlgorithm();

        const signature = MakeCadesBesSign_NPAPI(dataToSign, certificate, undefined);

        this.saveFormSign(signature, this.form.reportId);

        // this.sigMessage = "Подпись сформирована успешно:"+signature;
      } catch (err) {
        console.log(err);
      }
    }
  }

  sendForm() {

    this.service.acceptForm(
      this.form.operatorId,
      this.form.reportId,
      this.user,
      this.userComment
    ).subscribe(
      result => {

        const jQuery: any = $;
        jQuery.fancybox.close();

        this.notify.emit(result);

        if (!result.badnews) {
          this.toastyService.addSuccess('Отправка формы', 'Форма \'' + this.form.formName + '\' отправлена');
        } else {
          this.toastyService.addError('Отправка формы', '\'' + this.form.formName + '\':' + result.badnews);
        }
      },
      error => {
        console.error(error);
        this.toastyService.addError('Отправка формы', 'Произошла ошибка во время отправки формы \'' + this.form.formName + '\'');
      }
    );
  }

  ngOnDestroy(): void {
    this.clear();
    // console.log('FormSignatureComponent - ngOnDestroy');
    const div: any = $('#submitting_forms_details .popups_desc_in');
    div.getNiceScroll().hide();

    this.niceScrollInit = false;
  }

  getErrorMessage(e) {
    let err = e.message;
    if (!err) {
      err = e;
    } else if (e.number) {
      err += ' (0x' + this.decimalToHexString(e.number) + ')';
    }

    return err;
  }

  decimalToHexString(number) {
    if (number < 0) {
      number = 0xFFFFFFFF + number + 1;
    }

    return number.toString(16).toUpperCase();
  }
}
