import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileInfo'
})
export class FileInfoPipe implements PipeTransform {

  constructor() {
  }

  transform(value): string {
    let ext = value.fileName;
    if (ext.indexOf(".") > 0) {
      ext = ext.substring(ext.lastIndexOf(".") + 1);
    }
    return "(" + ext + ", " + this.formatFileSize(value.fileSize) + ")";
  }

  private formatFileSize (bytes) {
    const sufixes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return !bytes && '0 байт' || (bytes / Math.pow(1024, i)).toFixed(1) + " " + sufixes[i];
  }
}
