import {AbstractDataObject} from './common.model';


export enum SignatureState {
  plugin_init,
  cer_select,
  saving,
  signed
}

export class SignerInfo extends AbstractDataObject {
  // Владелец: <b>" + certObj.GetCertName()
  public user: string;
  // Издатель: <b>" + certObj.GetIssuer()
  public issuer: string;
  // Выдан: <b>" + certObj.GetCertFromDate()
  public fromDate: string;
  // Действителен до: <b>" + certObj.GetCertTillDate()
  public tillDate: string;
  // Криптопровайдер: <b>" + certObj.GetPrivateKeyProviderName()
  public keyProviderName: string;
  // Алгоритм ключа: <b>" + certObj.GetPubKeyAlgorithm()
  public pubKeyAlgorithm: string;

  constructor() {
    super();
  }
}

export class SignData extends AbstractDataObject {
  public signer: SignerInfo;
  public sign: string;
  public reportId: number;

  constructor() {
    super();
  }
}

export class LoadTemplateStatus extends AbstractDataObject {
  public success: boolean;
  public errorMessage: string;
  public nWarnings: number;
  public nCriticalWarnings: number;
}

export class CertificateRef extends AbstractDataObject {
  public text: string;
  public title: string;
  public value: string;

  constructor(text: string, title: string, value: string) {
    super();

    this.text = text;
    this.title = title;
    this.value = value;
  }
}

export class FormCellData extends AbstractDataObject {
  public rowId: number;
  public columnId: number;
  public rowName: string;
  public columnName: string;
  public rowNumber: string;
  public columnNumber: string;
  public value: number;
  public unitCode: string;
  public unitName: string;
  public unitNotation: string;

  constructor() {
    super();
  }
}

export class FormTitulData extends AbstractDataObject {
  public operatorName: string = '';
  public address: string = '';
  public comment: string = '';
  public zip: string = '';
  public inn: string = '';
  public gvcCode: string = '';
  public okved: string = '';
  public chiefName: string = '';
  public okato: string = '';
  public okpo: string = '';
  public okpoSub: string = '';
  public ogrn: string = '';
  public oktmo: string = '';
  public regionCode: string = '';
  public documentCode: string;
  public author: string;
  public authorPhone: string;
  public authorEmail: string;


  constructor() {
    super();
  }
}

export class ErrorRecord extends AbstractDataObject {
  public cellData: boolean;
  public fieldCode: string;
  public title: string;
  public message: string;
  public comment: string;
  public value: string;
  public dataPath: string;
  public showComment: boolean;

  constructor() {
    super();
  }
}

export class WarningRecord extends AbstractDataObject {
  public cellData: boolean;
  public fieldCode: string;
  public title: string;
  public message: string;
  public comment: string;
  public value: string;
  public dataPath: string;

  constructor() {
    super();
  }
}

export class FormHistory extends AbstractDataObject {
  public reportId: number;
  public status: string;
  public date: Date;
  public user: string;
  public warningData: WarningRecord[] = [];
  public errorData: ErrorRecord[] = [];
  public chatComment: ReportChatComment;

  constructor() {
    super();
  }
}

export class ReportChatComment extends AbstractDataObject {
  public id: number;
  public createDate: Date;
  public formVarId: number;
  public userId: number;
  public userName: string;
  public userPhone: string;
  public userRole: string;
  public reportHistoryId: number;
  public answerOnId: number;
  public comment: string;

  constructor() {
    super();
  }
}

export class FormComment extends AbstractDataObject {
  public id: number;
  public note: string;

  constructor() {
    super();
  }
}


export class FormHintModel extends AbstractDataObject {

  public formOkudHint: string;
  public formOkatoHint: string;
  public operatorNameHint: string;
  public addressHint: string;
  public zipHint: string;
  public documentCodeHint: string;
  public innHint: string;
  public gvcCodeHint: string;
  public okvedHint: string;
  public chiefNameHint: string;
  public formOkpoHint: string;
  public formOkpoSubHint: string;
  public formOgrnHint: string;
  public regionCodeHint: string;
  public oktmoHint: string;
  public formMainCodeHint: string;
  public formSubCodeHint: string;

  constructor() {
    super();
    this.formOkudHint = '';
    this.formOkatoHint = '';
    this.operatorNameHint = '';
    this.addressHint = '';
    this.zipHint = '';
    this.documentCodeHint = '';
    this.innHint = '';
    this.gvcCodeHint = '';
    this.okvedHint = '';
    this.chiefNameHint = '';
    this.formOkpoHint = '';
    this.formOkpoSubHint = '';
    this.formOgrnHint = '';
    this.regionCodeHint = '';
    this.oktmoHint = '';
    this.formMainCodeHint = '';
    this.formSubCodeHint = '';
  }
}

/**
 * Описание формы
 */
export class FormModel extends AbstractDataObject {
  public refuseFlag: number = 0;
  public badnews: string;
  public userId: number;
  public operatorId: number;
  public reportId: number;
  public formId: number;
  public year: number;
  public quarter: number;
  public month: number;
  private day: number;

  public hintModel: FormHintModel;


  public completePeriod: boolean;

  public summary: boolean;
  public regionCode: string;
  public createDate: Date;
  public updateDate: Date;
  public updateDateStr: string;

  public formFullName: string;
  public formName: string;

  public formOkud: string;

  public formCode: string;
  public formMainCode: string;
  public formSubCode: string;
  public periodicity: string;
  public completionDate: Date;
  public completionDateStr: string;

  public overdue: boolean;
  public deliveredAfterClosedPeriod: boolean;
  public receivedAfterClosedPeriodWithAllowing: boolean;
  public correctionAllow: boolean;
  public recommendedDate: Date;

  public endPeriodDate: Date;
  public endPeriodDateStr: string;

  public status: string;
  public state: string;
  public cumulative: boolean;
  public correction: boolean;
  public documentType: string;
  public adminComment: string;
  public refuseComment: string;

  public readonly: boolean;

  public histories: FormHistory[] = [];
  public titulData: FormTitulData = new FormTitulData();
  public formData: FormCellData[] = [];

  public warningData: WarningRecord[] = [];
  public errorData: ErrorRecord[] = [];
  public errors: boolean;

  public reportSign: string;

  constructor() {
    super();

    this.titulData = new FormTitulData();
    this.hintModel = new FormHintModel();
    this.formData = [];
  }
}

export class CorrectionAllowResponse extends AbstractDataObject {
  public allow: boolean;
  public closeDate: Date;

  constructor() {
    super();
  }
}

export class ResultMessage extends AbstractDataObject {
  public description: string;
  public code: string;
  public id: number;
  public success: boolean;

  constructor() {
    super();
  }
}
