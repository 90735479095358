import {Component, AfterViewInit} from '@angular/core';
import {AuthenticationService} from '../../service/authentication.service';
import {Config} from '../../config';
import {Router} from '@angular/router';
import {FANCYBOX_COMMON_OPTIONS} from '../../model/common.model';
import {DataService} from '../../service/data.service';

export class UserSec {
  public email: string;
  public pwd: string;

  constructor(email: string, pwd: string) {
    this.email = email;
    this.pwd = pwd;
  }
}

declare var $: JQueryStatic;

@Component({
  templateUrl: './login.component.html',
  providers: [AuthenticationService, Config, DataService]
})
export class LoginComponent implements AfterViewInit {
  public authActive = true;

  public forgotSubmited = false;
  public forgotEmail: string;
  public errorForgotMessage: string;
  public forgotMessage: string;

  private invalidMessage = 'Неправильное имя или пароль';
  private userNotFoundMessage = 'Пользователь с указанным логином не найден';
  private serverErrorMessage = 'Произошла ошибка, обратитесь к администратору';

  public user: UserSec;

  public errorMessage: string;

  public submited = false;
  public submitedConfirm = true;

  public mode = 'login';

  public confirmUser: any;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.user = new UserSec(null, null);
  }

  clearForgotDialog() {
    this.forgotSubmited = false;
    this.forgotEmail = null;
    this.errorForgotMessage = null;
    this.forgotMessage = null;
  }

  ngAfterViewInit(): void {
    const self = this;

    const box: any = $('.fancy_forgot_password');

    box.fancybox({
      FANCYBOX_COMMON_OPTIONS,
      afterShow: function () {
        self.clearForgotDialog();
      },
      afterClose: function () {
        self.clearForgotDialog();
      }
    });

    const jQuery: any = $;
    jQuery.scrollTo('#header');
  }

  toggleAuthTab() {
    if (!this.authActive) {
      this.authActive = true;
    }
  }

  toggleRegTab() {
    if (this.authActive) {
      this.authActive = false;
    }
  }

  isEmpty(val) {
    return val == null || val === '';
  }

  onForgotSubmit() {
    this.forgotSubmited = true;
    this.errorForgotMessage = null;
    this.forgotMessage = null;

    this.auth.confirmEmail(this.forgotEmail)
      .subscribe(
        result => {
          if (result === true) {
            this.forgotMessage = 'Письмо со ссылкой для восстановления пароля отправлено по адресу: ' + this.forgotEmail;
          } else {
            this.errorForgotMessage = 'Произошла ошибка отправки письма';
          }
        },
        error => {
          this.forgotSubmited = false;
          const error1 = error.error;
          this.errorForgotMessage = error1 && error1.message ? error1.message : error1;
          console.log(error1);
        }
      );
  }

  onConfirmBack() {
    this.mode = 'login';
    this.submited = false;
    this.submitedConfirm = false;
    this.errorMessage = null;
  }

  onSubmitConfirm() {
    this.submitedConfirm = true;
    this.errorMessage = null;

    this.auth.confirmEmail(this.confirmUser.email)
      .subscribe(
        result => {
          if (result === true) {
            this.mode = 'sent';
          } else {
          }
        },
        error => {
          this.submitedConfirm = false;
          this.errorMessage = error && error.message ? error.message : error;
          console.log(error);
        }
      );
  }

  /**
   *  Авторизация пользователя
   */
  onSubmit() {
    this.errorMessage = null;
    this.submited = true;

    this.auth.login(this.user.email, this.user.pwd)
      .subscribe(
        result => {
          if (result === true) {
            this.router.navigate(['personal-area']);
          } else {
            const user = localStorage.getItem('confirmUser');

            if (user) {
              this.confirmUser = JSON.parse(user);
              this.submitedConfirm = false;
              this.mode = 'confirm';
            } else {
              this.submited = false;
              this.errorMessage = this.invalidMessage;
            }
          }
        },
        error => {
          console.log(error);

          this.submited = false;

          if (error && error.status) {
            if (error.status === 403 || error.status === 503 || error.status === 401
              || error.status === 500 || error.status === 503) {
              this.errorMessage = error.error.message;
            } else if (error.status === 404 || error.status === 412) {
              this.errorMessage = this.userNotFoundMessage;
            }
          } else if (error && error.message) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = this.serverErrorMessage;
          }
        }
      );
  }
}
