import { Injectable } from '@angular/core';
import { Config } from '../config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PasswordChangeLink } from '../model/common.model';
import { HttpHeaders } from '@angular/common/http';
import { Subscription ,  Observable } from 'rxjs';
import { RestClientService } from './rest-client.service';
import { catchError, map } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { timer } from 'rxjs/internal/observable/timer';

@Injectable()
export class AuthenticationService {

  private REST_SERVICE_TOKEN_REFRESH = '/security/refreshToken';

  public token: string;
  private jwtHelper: JwtHelperService;
  private refreshSubscription: Subscription;

  constructor(private restClient: RestClientService, private config: Config) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;

    this.jwtHelper = new JwtHelperService();
  }

  loggedIn(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.token) {
      if (!this.jwtHelper.isTokenExpired(currentUser.token)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLinkActive(userId, time, hash, data): Observable<PasswordChangeLink> {

    return this.restClient.post({
      url: '/security/checkLinkForExpired',
      body: {user: userId, time: time, hash: hash, data: data},
      options: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    }).pipe(
      map((responseData: any) => {
        if (responseData) {
          return responseData.data;
        }
      }),
      catchError(this.handleError)
    )
  }

  saveConfirm(userId, time, hash, data, link): Observable<boolean> {

    return this.restClient.post({
      url: '/security/updateinfo',
      body: {user: userId, time: time, hash: hash, data: data, link: link},
      options: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    })
      .pipe(
        map(responseData => {
          return responseData ? true : false;
        }));
  }

  confirmEmail(email): Observable<boolean> {

    const encodedEmail: string = encodeURI(email);

    return this.restClient.post({
      url: '/security/sendconfirm',
      options: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'email': encodedEmail
        })
      }
    })
      .pipe(
        map(responseData => {
          return responseData ? true : false;
        }));
  }

  public updateToken() {
    console.log('updateToken');

    const user = JSON.parse(localStorage.getItem('currentUser'));

    this.restClient.post({
      url: this.REST_SERVICE_TOKEN_REFRESH,
      body: {id: user.id, email: user.email},
      options: {
        authorization: true
      }
    })
      .subscribe(
        data => {
          if (data && data.token) {

            const currentUser = JSON.parse(localStorage.getItem('currentUser'));

            this.token = data.token;

            currentUser.token = data.token;

            console.log(data.token);

            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          }
        },
        error => {
          this.handleError(error);
          this.logout();
        }
      );
  }

  public scheduleRefresh() {
    console.log('scheduleRefresh');
    console.log(this.token);

    if (this.token) {
      const jwtIat = this.jwtHelper.decodeToken(this.token).iat;
      const jwtExp = this.jwtHelper.decodeToken(this.token).exp;
      const iat = new Date(0);
      const exp = new Date(0);

      const delay = (exp.setUTCSeconds(jwtExp) - iat.setUTCSeconds(jwtIat)) - this.config.TOKEN_REFRESH_TIME;

      const source$ = interval(delay);

      this.refreshSubscription = source$.subscribe(() => {
        this.updateToken();
      });
    }
  }

  public startupTokenRefresh(token) {
    console.log('startupTokenRefresh');
    console.log(token);

    if (token) {
      const now: number = new Date().valueOf();
      const jwtExp: number = this.jwtHelper.decodeToken(token).exp;
      const exp: Date = new Date(0);
      exp.setUTCSeconds(jwtExp);

      const delay: number = exp.valueOf() - now - this.config.TOKEN_REFRESH_TIME;

      const source = timer(delay);

      source.subscribe(() => {
        this.scheduleRefresh();
        this.updateToken();
      });
    }
  }

  login(username, password): Observable<boolean> {

    const encodedUsername: string = encodeURI(username);
    const encodedPassword: string = encodeURI(password);

    return this.restClient.post({
      url: '/security/authenticate',
      options: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'email': encodedUsername,
          'password': encodedPassword
        })
      }
    })
      .pipe(
        map((responseData: any) => {

            if (responseData && responseData.user && responseData.user.status && responseData.user.status === 'CONFIRMATION') {
              localStorage.setItem('confirmUser', JSON.stringify(
                {
                  username: username,
                  id: responseData.user.id,
                  operatorId: responseData.user.operatorId,
                  email: responseData.user.email,
                  role: responseData.user.role,
                  status: responseData.user.status
                }
              ));
            } else if (responseData && responseData.token) {
              this.token = responseData.token;

              localStorage.setItem('currentUser', JSON.stringify(
                {
                  username: username,
                  token: responseData.token,
                  name: responseData.user.name,
                  id: responseData.user.id,
                  operatorId: responseData.user.operatorId,
                  email: responseData.user.email,
                  role: responseData.user.role,
                  status: responseData.user.status
                }
              ));

              this.startupTokenRefresh(this.token);

              return true;
            } else {
              return false;
            }
          }
        )
      );
  }

  logout(): void {
    console.log('logout');

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.token = null;
    localStorage.removeItem('currentUser');
  }

  private handleError(error: Response | any) {
    let errMsg: string;

    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
