import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Config} from '../../../config';
import {DataService} from '../../../service/data.service';
import {OperatorProfile, ProfileDataChange} from '../../../model/common.model';

import {Subscription} from 'rxjs';
import {timer} from 'rxjs/internal/observable/timer';
import {Form, NgForm} from "@angular/forms";

declare var $: JQueryStatic;

@Component({
  templateUrl: './profile.component.html',
  providers: [DataService, Config]
})
export class ProfileComponent implements OnInit, AfterViewInit {

  public REST_SERVICE_PROFILE_GET = '/profile/get';

  public profile: OperatorProfile;
  public errorMessage: string;
  public changeErrorMessage: string;
  public changeResultMessage: string;
  public showChangePswdForm = true;

  public showClosed = false;

  public changeData: ProfileDataChange;

  constructor(private dataService: DataService) {
    this.profile = new OperatorProfile();
    this.changeData = new ProfileDataChange();
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.operatorId) {
      this.dataService.getAuthDataByParams(this.REST_SERVICE_PROFILE_GET, {'operatorId': currentUser.operatorId})
        .subscribe(
          data => {
            this.profile = data;
            const source = timer(500);
            source.subscribe(() => {
              ProfileComponent.updateServiceChosen();
            });
          },
          error => this.errorMessage = <any>error
        );
    } else {
      console.error('Operator ID not found');
    }
  }

  static updateServiceChosen() {
    console.log('updateServiceChosen');
    $('.tltip').webuiPopover({
      closeable: true,
      placement: 'top',
      multi: true
    });
  }

  ngAfterViewInit(): void {
    const changeDataDialog: any = $('#change_password_lnk');
    changeDataDialog.fancybox({
      fitToView: false,
      width: 'auto',
      height: 'auto',
      margin: [0, 0, 0, 0],
      autoSize: false,
      padding: [0, 0, 0, 0]
    });
  }

  onChangeSubmit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (!currentUser || !currentUser.id) {
      console.error('Operator ID not found');
      return;
    }

    if (this.changeData.operatorNewPwd !== this.changeData.operatorReNewPwd) {
      this.changeResultMessage = 'Ошибка смены пароля: повторный ввод пароля не совпадает';
      return;
    }

    const postData = JSON.stringify({
      userId: currentUser.id,
      operatorNewPwd: this.changeData.operatorNewPwd,
      operatorReNewPwd: this.changeData.operatorReNewPwd,
      operatorOldPwd: this.changeData.operatorOldPwd
    });

    this.dataService.changePassword(postData)
      .subscribe(
        data => {
          this.changeResultMessage = 'Пароль успешно изменен';
          this.changeData.operatorOldPwd = '';
          this.changeData.operatorNewPwd = '';
          this.changeData.operatorReNewPwd = '';
        },
        error => {
          let message = JSON.parse(error.substring(error.indexOf('{'))).message;
          if (!message) {
            message = 'Ошибка смены пароля.';
          }
          this.changeResultMessage = message;
        }
      );
  }
}
