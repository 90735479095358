// <reference path="../../../../node_modules/es6-promise/es6-promise.d.ts"/>
import {Injectable} from '@angular/core';
// Statics
import {Observable} from 'rxjs';
import {DataService} from './data.service';

@Injectable()
export class FormsService {
  private REST_SERVICE_FORMS_LIST = '/forms/form-list';
  private REST_SERVICE_GET_ACTIVE_YEAR = '/forms/active-year';

  constructor(private dataService: DataService) {
  }

  getForms(userId: number): Observable<any> {
    return this.dataService.getAuthDataByParams(this.REST_SERVICE_FORMS_LIST, {'userId': userId});
  }

  getActiveYear(): Observable<any> {
    return this.dataService.authGetData(this.REST_SERVICE_GET_ACTIVE_YEAR);
  }
}
