import {
  Component, AfterViewInit, OnInit, ElementRef, ViewChild, OnChanges, SimpleChanges,
  OnDestroy
} from '@angular/core';
import { HomeFormService } from '../../service/homeform.service';
import { Config } from '../../config';
import { MainFormData } from '../../model/common.model';
import { RequiredFormList, RequiredFormListRequest } from '../../model/formlist.model';
import { DataService } from '../../service/data.service';
import { timer } from 'rxjs/internal/observable/timer';

declare var $: JQueryStatic;

@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
  providers: [HomeFormService, Config, DataService]
})
export class HomeFormComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @ViewChild('licNumber') elLicNumber: ElementRef;
  @ViewChild('selectService') elSelectService: ElementRef;

  errorMessage: string;

  public serviceTypeIsSelected = false;

  public showQuarterly = false;
  public showYearly = false;

  public formData: MainFormData = new MainFormData();

  public formList: RequiredFormList = new RequiredFormList();

  public formRequest: RequiredFormListRequest = new RequiredFormListRequest();

  constructor(private service: HomeFormService) {
  }

  ngOnInit() {
    this.service.getMainFormData()
      .subscribe(
        data => {
          this.formData = data;

          const source = timer(500);

          source.subscribe(() => {
            this.updateServiceChosen();
          });
        },
        error => this.errorMessage = <any>error
      );
  }

  selectValueChanged(mainScope, val) {
    if (val.selected !== -1) {
      mainScope.data.serviceTypeIsSelected = true;
    } else {
      mainScope.data.serviceTypeIsSelected = false;
    }

    console.log(mainScope.data.serviceTypeIsSelected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("ngOnChanges");
    console.log(changes);
  }


  updateServiceChosen() {
    // console.log("updateServiceChosen");

    $('#selectService').trigger('chosen:updated');
  }

  closeFormList() {
    // this.service;
  }

  openFormList() {

    // console.log("openFormList");
    //
    const serviceSelect: number = $('#selectService').chosen().val();
    const isPrimary: boolean = $('#isPrimary').is(':checked');

    if (serviceSelect !== -1 ) {
      this.service.getFormInfoList(serviceSelect, isPrimary)
        .subscribe(
          data => {
            this.formList = data;
            this.showYearly = data.yearly.length > 0;
            this.showQuarterly = data.quarterly.length > 0;
          },
          error => {
            console.log(error);
          }
        );
    } else {
      console.log('is empty');
    }
  }

  initLists() {
    const chosen_params = {
      'disable_search': true, // поле поиска выключено
      width: '100%',
      touchbehavior: false
    };

    const serviceSelect: any = $(this.elSelectService.nativeElement);

    serviceSelect.chosen(chosen_params);

    serviceSelect.chosen().change(this, this.selectValueChanged);

    $('.chosen-results').niceScroll({
      horizrailenabled: false,
      autohidemode: false,
      cursorfixedheight: 20,
      cursordragontouch: true,
      cursordragspeed: 3,
      cursorcolor: '#18338b',
      touchbehavior: false,
      background: '#cccccc',
      cursorwidth: '8px',
      cursorborder: '1px',
      zindex: 560,
      railpadding: ({top: 0, right: 0, left: 0, bottom: 0}),
      railoffset: true,
    });

    $('.discover_btn').bind('click', this, this.discoverOpen);

    $('.discover_btn_close').click(function () {
      $(this).hide();
      $('.blc_annual_forms').slideUp(750);
      $('.discover_btn').show();
    });

    $('.discover_btn_close').hide();
  }

  onSubmit(form: any): void {
  }

  discoverOpen(data) {
    $(this).hide();
    $('.blc_annual_forms').slideDown(750);
    $('.discover_btn_close').show();
  }

  ngAfterViewInit() {
    this.initLists();
    // $("form").data("validator").settings.ignore = ":hidden:not(select)";
  }

  ngOnDestroy(): void {
    console.log('HomeFormComponent - ngOnDestroy');
    console.log($('.chosen-results'));
    // $('.chosen-results').getNiceScroll().hide();
  }
}
