import {Directive, Output, EventEmitter} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[number-mask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)': 'onInputChange($event.target.value, true)'
  }
})
export class NumberMask {
  constructor(public model: NgControl) {
  }

  @Output() rawChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(event, backspace) {
    var newVal = event.replace(/[^0-9\.]/g, '');
    if (newVal.length == 0) {
      newVal = '0';
    }

    var fraction = parseFloat(newVal) - parseInt(newVal);
    var int = parseInt(newVal);

    if (backspace) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    //newVal = int + "." + fraction;
    this.model.valueAccessor.writeValue(newVal);
  }
}

