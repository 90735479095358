import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {FormModel, SignData, ResultMessage, CorrectionAllowResponse} from '../model/form.model';
import {Observable} from 'rxjs';


@Injectable()
export class FormService {

  private REQUEST_FORM_URL = '/form/form-get';
  private REQUEST_SAVE_DRAFT_FORM_URL = '/form/form-draft-save';
  private REQUEST_FORM_CHECK_URL = '/form/form-check';
  private REQUEST_FORM_ACCEPT_URL = '/form/form-accept';
  private REQUEST_SAVE_REFUSE_FORM_URL = '/form/form-refuse';
  private REQUEST_SAVE_REJECT_REFUSE_FORM_URL = '/form/form-reject-refuse-save';
  private REQUEST_GET_FORM_COMMENT = '/form/form-get-comment';
  private REQUEST_GET_FORM_HINT = '/form/form-get-hint';
  private REQUEST_GET_FORM_SIGN = '/form/form-sign';
  private REQUEST_GET_IS_CORRECTION_ALLOW = '/form/form-is-correction-allow';

  constructor(private dataService: DataService) {
  }

  getForm(userId: number,
          operatorId: number,
          formId: number,
          year: number,
          quarter: number,
          month: number,
          correction: boolean,
          summary: boolean,
          pristine: boolean): Observable<FormModel> {
    return this.dataService.authPostData(this.REQUEST_FORM_URL,
      JSON.stringify({
        userId: userId,
        operatorId: operatorId,
        formId: formId,
        year: year,
        quarter: quarter,
        month: month,
        correction: correction,
        summary: summary,
        pristine: pristine
      }));
  }

  saveDraft(form: FormModel) {
    return this.dataService.authPostData(this.REQUEST_SAVE_DRAFT_FORM_URL,
      JSON.stringify(form)
    );
  }

  saveRejectRefuse(form: FormModel) {
    return this.dataService.authPostData(this.REQUEST_SAVE_REJECT_REFUSE_FORM_URL,
      JSON.stringify(form)
    );
  }

  saveRefuse(form: FormModel) {
    return this.dataService.authPostData(this.REQUEST_SAVE_REFUSE_FORM_URL,
      JSON.stringify(form)
    );
  }

  checkForm(operatorId: number, reportId: number, userId: number, userComment: string, universalTemplate: boolean) {
    return this.dataService.authPostData(this.REQUEST_FORM_CHECK_URL,
      JSON.stringify({
        userId: userId,
        operatorId: operatorId,
        reportId: reportId,
        userComment: userComment,
        universalTemplate: universalTemplate
      })
    );
  }

  acceptForm(operatorId: number, reportId: number, userId: number, userComment: string) {
    return this.dataService.authPostData(this.REQUEST_FORM_ACCEPT_URL,
      JSON.stringify({
        userId: userId,
        operatorId: operatorId,
        reportId: reportId,
        userComment: userComment
      })
    );
  }

  getFormComment(formId: number) {

    return this.dataService.authPostData(this.REQUEST_GET_FORM_COMMENT,
      JSON.stringify({
        formId: formId
      }));
  }

  saveSign(data: SignData): Observable<ResultMessage> {
    return this.dataService.authPostData(this.REQUEST_GET_FORM_SIGN,
      JSON.stringify(data)
    );
  }

  isCorrectionAllow(operatorId: number, reportId: number, userId: number): Observable<CorrectionAllowResponse> {
    return this.dataService.authPostData(this.REQUEST_GET_IS_CORRECTION_ALLOW,
      JSON.stringify({
        userId: userId,
        operatorId: operatorId,
        reportId: reportId
      }));
  }
}
