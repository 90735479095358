import {Directive, Output, EventEmitter} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[phone-mask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)': 'onInputChange($event.target.value, true)'
  }
})
export class PhoneMask {
  constructor(public model: NgControl) {
  }

  @Output() rawChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(event, backspace) {
    var newVal = event.replace(/\D/g, '');

    if (newVal[0] == '7') {
      newVal = newVal.substring(1);
    }
    if (newVal.length == 12 || (backspace && newVal.length <= 4)) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    if (newVal.length == 0) {
      newVal = '';
    }

    if (newVal.length > 0) {
      newVal = newVal.replace(/^(\d+)/g, '+7 $1');
    }

    /*
     if(newVal.length<=3) {
     newVal = newVal.replace(/^(\d{1,3})/g, '+7 ($1)');
     }else if(newVal.length<=6){
     newVal = newVal.replace(/^(\d{3})(\d{1,3})/g,'+7 ($1)-$2');
     }else if(newVal.length<=8){
     newVal = newVal.replace(/^(\d{3})(\d{3})(\d{1,2})/g,'+7 ($1)-$2-$3');
     }else {
     newVal = newVal.replace(/^(\d{3})(\d{3})(\d{2})(\d{1,2})/, '+7 ($1)-$2-$3-$4');
     }
     */
    this.model.valueAccessor.writeValue(newVal);
  }
}

