import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS, FormControl} from '@angular/forms';

function validateInnFactory() {
  return (c: FormControl) => {
    let EMAIL_REGEXP = /^[0-9]{10}$|^[0-9]{12}$/i;

    let error = {
      validateInn: {
        valid: false
      }
    };

    console.log("Begin validate INN = " + c.value);

    if (!c.value) {
      return error;
    }

    if (!EMAIL_REGEXP.test(c.value)) {
      return error;
    }
    else {
      var str: any = c.value;

      if (str.length == 10) {
        var result = ((
            2 * str.charAt(0) +
            4 * str.charAt(1) +
            10 * str.charAt(2) +
            3 * str.charAt(3) +
            5 * str.charAt(4) +
            9 * str.charAt(5) +
            4 * str.charAt(6) +
            6 * str.charAt(7) +
            8 * str.charAt(8)
          ) % 11 ) % 10;

        if (result != str.charAt(9)) {
          return error;
        }
      }
      else if (str.length == 12) {
        var result1 = ((
            7 * str.charAt(0) +
            2 * str.charAt(1) +
            4 * str.charAt(2) +
            10 * str.charAt(3) +
            3 * str.charAt(4) +
            5 * str.charAt(5) +
            9 * str.charAt(6) +
            4 * str.charAt(7) +
            6 * str.charAt(8) +
            8 * str.charAt(9)
          ) % 11 ) % 10;

        var result2 = ((
            3 * str.charAt(0) +
            7 * str.charAt(1) +
            2 * str.charAt(2) +
            4 * str.charAt(3) +
            10 * str.charAt(4) +
            3 * str.charAt(5) +
            5 * str.charAt(6) +
            9 * str.charAt(7) +
            4 * str.charAt(8) +
            6 * str.charAt(9) +
            8 * str.charAt(10)
          ) % 11 ) % 10;

        console.log("INN r1 = " + result1);
        console.log("INN r2 = " + result2);

        if (!(str.charAt(10) == result1 && str.charAt(11) == result2)) {
          return error;
        }

      }
      else {
        return error;
      }
    }

    return null;
  };
}

@Directive({
  selector: '[validateInn][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InnValidator), multi: true}
  ]
})
export class InnValidator {
  validator: Function;

  constructor() {
    this.validator = validateInnFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
