import {Component, ViewChild, ElementRef, AfterViewInit, LOCALE_ID, OnInit} from '@angular/core';
import {Config} from '../../../config';
import {DataService} from '../../../service/data.service';
import {DraftModel, RequestDraftModel, FormFilterItem} from '../../../model/draft.model'; // OrderField
import {AngularProfiler} from '@angular/platform-browser/src/browser/tools/common_tools';
import { timer } from 'rxjs/internal/observable/timer';

declare var $: JQueryStatic;

@Component({
  templateUrl: './draft-forms.component.html',
  providers: [DataService, Config, {provide: LOCALE_ID, useValue: 'ru-RU'}]
})
export class DraftFormsComponent implements OnInit, AfterViewInit {
  private REST_SERVICE_DRAFT_LIST_GET = '/forms/draft-list';

  @ViewChild('dateRange') elDateRange: ElementRef;
  @ViewChild('selectForm') elSelectForm: ElementRef;

  public listPlaceholder = 'Выберите из списка';

  public operatorId: number;

  public model: DraftModel;

  constructor(private dataService: DataService) {
    this.model = new DraftModel();
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.operatorId) {
      this.operatorId = currentUser.operatorId;

      const request: RequestDraftModel = new RequestDraftModel();

      request.operatorId = currentUser.operatorId;
      request.filter = this.model.filter;
      request.orderDateSort = this.model.orderDateSort;
      request.orderFormSort = this.model.orderFormSort;
      request.orderPeriodSort = this.model.orderPeriodSort;
      request.orderSubjectSort = this.model.orderSubjectSort;

      this.dataService.authPostData(
        this.REST_SERVICE_DRAFT_LIST_GET, JSON.stringify(request))
        .subscribe(
          data => {
            const previousFileteredForms: FormFilterItem[] = this.model.filterForms;
            this.model = data;

            const source = timer(200);
            source.subscribe(() => {
              if (this.arraysEqual(previousFileteredForms, this.model.filterForms) === false) {
                this.updateServiceChosen();
              }
            });
          },
          error => {
            console.error(<any>error);
          }
        );
    } else {
      console.error('Operator ID not found');
    }
  }


  arraysEqual(prev, now) {
    if (prev === now) { return true; }
    if (prev == null || now == null) { return false; }
    if (prev.length !== now.length) { return false; }

    for (let i = 0; i < prev.length; ++i) {
      if (this.compareFormsData(prev[i], now[i]) === false) { return false; }
    }

    return true;
  }

  compareFormsData(left: FormFilterItem, right: FormFilterItem) {

    if (left.formId !== right.formId) { return false; }

    if (left.formName !== right.formName) { return false; }

    return true;
  }

  sortClass(value) {
    if (value === 0) {
      return ['nosort'];
    } else if (value === 1) {
      return ['ascsort'];
    } else if (value === 2) {
      return ['descsort'];
    }

    return [];
  }

  onPageChange(value) {
    this.model.filter.page = value;
    this.reloadData();
  }

  changeDateSortOrder() {
    this.model.orderDateSort.sort = this.nextSort(this.model.orderDateSort.sort);

    this.reloadData();
  }

  nextSort(current: number): number {
    current += 1;

    if (current > 2) { current = 0; }

    return current;
  }

  changeSubjectSortOrder() {
    this.model.orderSubjectSort.sort = this.nextSort(this.model.orderSubjectSort.sort);

    this.reloadData();
  }

  changeFormSortOrder() {
    this.model.orderFormSort.sort = this.nextSort(this.model.orderFormSort.sort);

    this.reloadData();
  }

  changePeriodSortOrder() {
    this.model.orderPeriodSort.sort = this.nextSort(this.model.orderPeriodSort.sort);

    this.reloadData();
  }

  updateServiceChosen() {
    const selected: any = $('#selectForm');
    $('#selectForm').trigger('chosen:updated');
  }

  formSelection(value, val) {

    const formId: number = val.selected;

    // console.log(formId);

    value.data.model.filter.formId = formId === -1 ? null : formId;

    // console.log(this);

    value.data.reloadData();
  }

  ngAfterViewInit() {
    this.initFormSelector();
    this.initDatePicker();
  }

  clearFilter() {

    this.model.filter.begin = null;
    this.model.filter.end = null;
    this.model.filter.formId = null;

    $('#dateRange').data('dateRangePicker').clear();
    $('#selectForm').val('-1');
    $('#selectForm').trigger('chosen:updated');
    this.reloadData();
  }

  initFormSelector() {
    const selectForm: any = $(this.elSelectForm.nativeElement);

    const chosen_params = {
      'disable_search': true, // поле поиска выключено
      width: '100%',
      touchbehavior: false
    };

    selectForm.chosen(chosen_params);

    selectForm.chosen().change(this, this.formSelection);
  }

  initDatePicker() {
    const rangeField: any = $(this.elDateRange.nativeElement);

    $('#dateRange').dateRangePicker({
      format: 'DD MMM YY',
      startOfWeek: 'monday', // or sunday
      container: '.il_desc',
      language: 'ru',
    });

    rangeField.bind('datepicker-open', function () {
      $('.over').addClass('bg');
    });

    rangeField.bind('datepicker-close', function () {
      $('.over').removeClass('bg');
    });

    rangeField.bind('datepicker-apply', this, this.periodSelection);

  }

  // TODO: не привязанно
  clearPeriod(value, period) {
    value.data.model.filter.begin = null;
    value.data.model.filter.end = null;
    console.log('clear!');
    console.log(value.data.model);

    value.data.reloadData();
  }

  periodSelection(value, period) {
    value.data.model.filter.begin = period.date1;
    value.data.model.filter.end = period.date2;
    console.log('apply!');
    console.log(value.data.model);

    value.data.reloadData();
  }
}
