import {Directive, Output, EventEmitter} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[email-mask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)': 'onInputChange($event.target.value, true)'
  }
})
export class EmailMask {
  public regexik: string = '/[^@]+@[^@\.]+\.[^@]+/g'; //\S+@\S+\.\S+
  constructor(public model: NgControl) {
  }

  @Output() rawChange: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(event, backspace) {
    //валидация на этапе ввода бессмысленная затея.
    var good = event.match(this.regexik);
    var newVal = event;

    var countOfDog = (event.match(new RegExp("@", "g")) || []).length;
    var countOfPoint = (event.match(new RegExp("\\.", "g")) || []).length;
    if (countOfPoint > 1 && countOfDog != 1) {
      good = false;
    }

    this.model.valueAccessor.writeValue(newVal);
  }
}

