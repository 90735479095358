import {Component, AfterViewInit} from '@angular/core';

declare var $: JQueryStatic;

@Component({
  templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit {
  renderPaginationBullet(swipper, index) {
    const className = swipper.params.bulletClass;
    return '<span class="' + className + '">' + (index + 1) + '</span>';
  }

  ngAfterViewInit(): void {
    const self = this;

    const main_slr = new Swiper('.main_slr', {
      loop: true,
      autoplay: 6000,
      speed: 3200,
      slidesPerView: 1,
      paginationClickable: true,
      spaceBetween: 0,
      preventClicks: false,
      pagination: '.swiper-pagination',
      paginationBulletRender: self.renderPaginationBullet
    });
  }


  niceScrollInit() {

    $('#on_communication .asc').niceScroll({
      cursorcolor: '#18338b',
      touchbehavior: false,
      autohidemode: false,
      background: '#cccccc',
      cursorwidth: '8px',
      cursorborder: '1px',
      cursorfixedheight: 39,
      zindex: 560,
      railpadding: ({top: 0, right: 0, left: 0, bottom: 0}),
      railoffset: true,
    });

    $('#ascrail2003').appendTo($('#on_communication .popups .popups_desc'));
  }

  showFZ() {
    const self = this;

    const jQuery: any = $;

    const dialog = jQuery.fancybox('#on_communication', {
      fitToView: false,
      width: 'auto',
      height: 'auto',
      margin: [0, 0, 0, 0],
      autoSize: false,
      padding: [0, 0, 0, 0],
      afterShow: self.niceScrollInit()
    });
  }

  showKOAP() {
    const self = this;

    const jQuery: any = $;

    const dialog = jQuery.fancybox('#provide_info', {
      fitToView: false,
      width: 'auto',
      height: 'auto',
      margin: [0, 0, 0, 0],
      autoSize: false,
      padding: [0, 0, 0, 0]
    });
  }
}
