import {Component, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {AppToastyService} from '../../service/apptoasty.service';
import {Config} from '../../config';
import {DataService} from '../../service/data.service';

declare var $: JQueryStatic;

@Component({
  selector: 'app-ask-control',
  templateUrl: './ask-control.component.html',
  providers: [AppToastyService, Config, DataService]
})
export class AskControlComponent implements AfterViewInit {
  private ANIMATION_TIME = 350;

  private REST_PATH = '/question/send';

  @ViewChild('askDiv') elAskDiv: ElementRef;
  @ViewChild('askForm') elAskForm: ElementRef;

  public authorEmail: string;
  public message: string;
  public captcha: string;
  public captchaURL: string = "/captcha?"+Date.now();


  constructor(private toastyService: AppToastyService, private dataService: DataService) {
  }

  onSubmit() {
    this.dataService.postFullData(this.REST_PATH,
      JSON.stringify({email: this.authorEmail, message: this.message, captcha: this.captcha}))
      .subscribe(
        result => {
          if (result.success) {
              //console.dir("dataService.postFullData => result = ", result);
              this.toastyService.addSuccess(
                'Отправка сообщения',
                'Вопрос специалисту отправлен. Ответ специалиста будет отправлен на Email: ' + this.authorEmail
              );
              this.closeForm();
          } else {
            this.toastyService.addError(
              'Отправка сообщения',
              result.errorMessage
            );
          }
        },
        error => {
          this.toastyService.addError(
            'Отправка сообщения',
            'При отправке сообщения произошла ошибка'
          );
        }
      );
  }

  openForm() {
    $(this.elAskDiv.nativeElement).hide();
    $(this.elAskForm.nativeElement).slideDown(this.ANIMATION_TIME);
    this.captchaURL = "/captcha?" + Date.now();
  }

  closeForm() {
    this.authorEmail = null;
    this.message = null;
    this.captcha = null;
    $(this.elAskForm.nativeElement).slideUp(this.ANIMATION_TIME);
    setTimeout(this.showAskDiv(), this.ANIMATION_TIME);
  }

  showAskDiv() {
    $(this.elAskDiv.nativeElement).show();
  }

  ngAfterViewInit() {
  }
}
