import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Config } from '../config';
import { HttpObserve } from '@angular/common/http/src/client';

@Injectable()
export class RestClientService {

  constructor(private http: HttpClient, private config: Config) {
  }

  request(method: HttpMethod, requestConfig: HttpRequestConfig) {
    const options: HttpRequestOptions = requestConfig.options;
    let headers: HttpHeaders;
    let httpParams: HttpParams = new HttpParams();

    if (options) {
      if (options.authorization) {
        headers = this.getAuthRequestHeaders();
      } else {
        headers = options.headers;
      }

      if (options.killCache) {
        httpParams = httpParams.set('vanish_cache', `${Math.random()}`);
      }
    }

    if (requestConfig.queryParams) {
      for (const paramName of Object.keys(requestConfig.queryParams)) {
        httpParams = httpParams.set(paramName, requestConfig.queryParams[paramName]);
      }
    }

    return this.http.request(
      method,
      this.config.REST_BASE + requestConfig.url,
      {
        body: requestConfig.body,
        headers: headers,
        observe: options ? options.observe : null,
        params: httpParams,
        responseType: options ? options.responseType : null,
        withCredentials: options ? options.withCredentials : false
      }
    );
  }

  post(requestConfig: HttpRequestConfig) {
    return this.request('POST', requestConfig);
  }

  get(requestConfig: HttpRequestConfig) {
    return this.request('GET', requestConfig);
  }

  put(requestConfig: HttpRequestConfig) {
    return this.request('PUT', requestConfig);
  }

  patch(requestConfig: HttpRequestConfig) {
    return this.request('PATCH', requestConfig);
  }

  delete(requestConfig: HttpRequestConfig) {
    return this.request('DELETE', requestConfig);
  }

  getAuthRequestHeaders(): HttpHeaders {

    let token = '';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.token) {
      token = currentUser.token;
    }

    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    );
  }
}

export interface HttpRequestOptions {
  headers?: HttpHeaders;
  authorization?: true | false;
  killCache?: true | false;
  // TODO: Convert to enum after Typescript 2.7+
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  observe?: HttpObserve;
  withCredentials?: true | false;
}

export interface HttpQueryParam {
  key: string;
  value: any;
}

export interface HttpRequestConfig {
  url: string;
  queryParams?: [HttpQueryParam];
  body?: any;
  options?: HttpRequestOptions;
}

// TODO: Convert to enum after Typescript 2.7+
export type HttpMethod = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
  let result = '';
  for (let i = 0; i < substitutions.length; i++) {
    result += template[i];
    result += encodeURIComponent(substitutions[i]);
  }
  result += template[template.length - 1];
  return result;
}
