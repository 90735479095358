import {Validator, NG_VALIDATORS, AbstractControl, FormControl, NgModel} from "@angular/forms";
import {forwardRef, Attribute, Directive, Input} from "@angular/core";

@Directive({
  selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true}
  ]
})
export class EqualValidator implements Validator {
  @Input('validateEqual') target: NgModel;

  constructor(@Attribute('reverse') public reverse: string) {

  }

  private isReverse() {
    if (!this.reverse) return false;
    return this.reverse === 'true' ? true : false;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    let v = c.value;

    if (this.isReverse())// password input
    {
      if (this.target && v === this.target.value) {
        if (this.target && this.target.errors) {
          delete this.target.errors['validateEqual'];
          if (!Object.keys(this.target.errors).length) this.target.control.setErrors(null);
        }
      }

      if (this.target && v !== this.target.value) {
        this.target.control.setErrors({validateEqual: {valid: false}});
      }

    }
    else // re-password input
    {
      if (this.target && v !== this.target.value) {
        return {
          validateEqual: {
            valid: false
          }
        };

      }
    }

    return null;
  }
}
