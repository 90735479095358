import { Injectable } from '@angular/core';

@Injectable()
export class Config {

  // Время для обновления токена 10 секунд
  private _TOKEN_REFRESH_TIME = 10000;
  get TOKEN_REFRESH_TIME(): number {
    return this._TOKEN_REFRESH_TIME;
  }

  private _REST_BASE = 'rest';
  get REST_BASE(): string {
    return this._REST_BASE;
  }

  private _TOAST_TIMEOUT = 4000;
  get TOAST_TIMEOUT(): number {
    return this._TOAST_TIMEOUT;
  }
}
