import {Periodicity, FormStatus, YearStatus} from './enums.model';
import {AbstractDataObject} from './common.model';

export class FormInfo extends AbstractDataObject {
  // id шаблона формы
  public id: number;
  // id данных формы
  public varId: number;

  public name: string;
  public shortName: string;
  public code: string;
  public periodicity = 'quarterly';
  // Статус формы
  public status: FormStatus = FormStatus.required;
  // Количество сообщений для формы
  public messageCount = 0;

  public completionMonth: number;
  public completionDay: number;
  public year: number;
  public quarter: number;
  public month: number;
  public day: number;
  public completionDate: Date;
  public completionDateStr: string;
  public startDate: Date;
  public startDateStr: string;
  public completePeriod: boolean;

  constructor() {
    super();
  }
}

export class RequiredFormList extends AbstractDataObject {
  public quarterly: FormInfo[] = [];
  public yearly: FormInfo[] = [];

  constructor() {
    super();
  }
}

export class RequiredFormListRequest extends AbstractDataObject {
  public serviceType: number;
  public licNumber: string;
  public month: number;
  public year: number;
  public isPrimary: boolean;

  constructor() {
    super();
  }
}

export class FormDateList extends AbstractDataObject {
  // День в формате dd.MMMM 10 апр
  public dateDay: string;

  // Год
  public year: number;

  public allFail = false;

  public allAccepted = false;

  public containmentFail = false;

  public containmentAccepted = false;

  public completionDate: number;

  public formList: FormInfo[] = [];

  constructor(dateDay: string, year: number) {
    super();

    this.dateDay = dateDay;
    this.year = year;
  }
}
/*
 Клас описывает список форм сгруппированный по кварталам и году
 */
export class FormList extends AbstractDataObject {
  // Тип годовой список = true, квартальный список = false
  public typeYear: boolean;
  // Год
  public year: number;
  // Номер квартала
  public quarter: number;
  // Количество требуемых
  public requiredCount = 0;
  // Количество отосланных
  public sentCount = 0;
  // Количество принятых
  public acceptedCount = 0;
  // Количество ошибочных
  public errorCount = 0;
  // Количество отказов от заполнения
  public refusedCount = 0;
  // Количество отклоненных
  public rejectedCount = 0;
  // Количество в работе
  public workCount = 0;
  // Флаг окончания отчетного периода
  public completePeriod: boolean;

  public formDateList: FormDateList[] = [];

  constructor() {
    super();
  }
}

export class OperatorFormsYear extends AbstractDataObject {
  public year: number;
  public status: YearStatus = YearStatus.success;
  public list: FormList[] = [];

  constructor() {
    super();
  }
}

/*
 Объект описания списка форм
 */
export class OperatorForms extends AbstractDataObject {
  public operatorId = 0;
  public infos: OperatorFormsYear[] = [];
  public composite: boolean;
  public regionCode: string;

  constructor() {
    super();
  }
}

export class BranchListRequest extends AbstractDataObject {
  public operatorId: number;
  public formId: number;
  public year: number;
  public month: number;
  public refuseFlag = false;
  public userId: number;
}

export class BranchListItem extends AbstractDataObject {
  public reportId: number;
  public name: string;
  public operatorId: number;
  public status = 'required'; // FormStatus = FormStatus.required;
  public closed: boolean;
}

export class SubjectItem extends AbstractDataObject {
  public regionCode: string;
  public regionName: string;
  public items: BranchListItem[] = [];
  public collapse: boolean;
}

export class BranchListModel extends AbstractDataObject {
  public formName: string;
  public formId: number;
  public year: number;
  public quarter: number;
  public month: number;

  public requiredCount: number;
  public sentCount: number;
  public acceptedCount: number;
  public errorCount: number;
  public refusedCount: number;
  public rejectedCount: number;
  public workCount: number;

  public accepted: number;

  public consolidated: BranchListItem = new BranchListItem();

  public items: SubjectItem[] = [];
}

// export class BranchListItemByStatus extends AbstractDataObject{
//     public status     : FormStatus = FormStatus.required;
//     public items  : BranchListItem[] = [];
// }
