import {Component, AfterViewInit, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from "../../service/data.service";
import {forkJoin} from "rxjs";

declare var $: JQueryStatic;

@Component({
  templateUrl: './events.component.html',
  providers: [DataService]
})
export class EventComponent implements AfterViewInit, OnInit {
  public eventsActive = true;
  public showpreloader: number;

  public eventList: any;
  public docList: any;

  constructor(private router: Router, private dataService: DataService, private activatedRoute: ActivatedRoute) {
    this.showpreloader = 1;

    this.activatedRoute.params.subscribe(params => {
      if ("docs" === params['section']) {
        this.changeToDocs();
      }
    });
  }

  ngOnInit(): void {
    forkJoin(
      this.dataService.getData("/data-publication/events"),
      this.dataService.getData("/data-publication/docs")
    ).subscribe(
        data => {
          this.eventList = data[0];
          this.docList = data[1];
          this.showpreloader = 0;
        },
        error => {
          console.error(<any>error);
        }
      );
  }

  changeToEvents() {
    if (!this.eventsActive) {
      this.eventsActive = true;
    }
  }

  ngAfterViewInit(): void {
    const self = this;
  }

  changeToDocs() {
    if (this.eventsActive) {
      this.eventsActive = false;
    }
  }

  niceScrollInit(affix: string) {
    $('#news_' + affix + ' .popups_desc').niceScroll({
      cursorcolor: '#18338b',
      touchbehavior: false,
      autohidemode: false,
      background: '#cccccc',
      cursorwidth: '8px',
      cursorborder: '1px',
      cursorfixedheight: 39,
      zindex: 560,
      railpadding: ({top: 0, right: 0, left: 0, bottom: 0}),
      railoffset: true,
    });

    $('[id^=\'ascrail200\']').appendTo($('#news_' + affix + ' .popups_desc'));
  }
}
