import {AfterViewInit, Component, OnInit, Renderer} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from "../../service/data.service";

declare var $: JQueryStatic;

@Component({
  templateUrl: './faq.component.html',
  providers: [DataService]
})
export class FAQComponent implements OnInit, AfterViewInit {
  public showpreloader: number;
  public showMore = false;
  private sub: any;
  public itemList: any;

  constructor(private renderer: Renderer, private activatedRoute: ActivatedRoute, private dataService: DataService) {
    this.showpreloader = 1;
  }

  ngOnInit(): void {
    this.dataService.getData("/data-publication/faq")
      .subscribe(
        data => {
          this.itemList = data;
          this.showpreloader = 0;
        },
        error => {
          console.error(<any>error);
        }
      );
  }

  public onItemClick (itemId) {
    var elem = $('.dpItem' + itemId);
    elem.next().slideToggle().end().toggleClass('open');
    elem.parent().toggleClass('open');
    elem.find('.arw').toggleClass('open');
    elem.next().toggleClass('open');
    elem.closest('li').prev().toggleClass('bb');
  }

  ngAfterViewChecked(): void {
    if (this.showpreloader == 0) {
      if (this.sub) {
        const element = document.getElementById(this.sub);
        this.sub = null;
        if (!element) {
          return;
        }
        element.style.display = 'block';
        this.renderer.invokeElementMethod(element, 'focus');
      }
    }
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.sub = params['id']
    });
  }
}
