import {Config} from '../../config';
import {AuthenticationService} from '../../service/authentication.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../service/data.service';
import {FANCYBOX_COMMON_OPTIONS, PasswordChangeLink} from '../../model/common.model';

@Component({
  templateUrl: './confirm.component.html',
  providers: [AuthenticationService, Config, DataService]
})
export class ConfirmComponent implements OnInit {
  public id: number;
  public email: string;
  public pwd: string;
  public repwd: string;
  public submited = false;
  public success = false;
  public activeLink: PasswordChangeLink;
  public forgotSubmited = false;
  public forgotEmail: string;
  public errorForgotMessage: string;
  public forgotMessage: string;

  private hash: string;
  private time: string;

  private errorMessage: string;

  constructor(private auth: AuthenticationService, private route: ActivatedRoute, private router: Router) {
  }

  clearForgotDialog() {
    this.forgotSubmited = false;
    this.forgotEmail = null;
    this.errorForgotMessage = null;
    this.forgotMessage = null;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['u'];
    this.hash = this.route.snapshot.params['s'];
    this.time = this.route.snapshot.params['t'];
    this.isLinkActive();

    const box: any = $('.fancy_forgot_password');

    box.fancybox({
      FANCYBOX_COMMON_OPTIONS,
      afterShow: function () {
        this.clearForgotDialog();
      },
      afterClose: function () {
        this.clearForgotDialog();
      }
    });
  }

  login() {
    this.router.navigate(['login']);
  }

  isLinkActive() {
    this.auth.isLinkActive(this.id, this.time, this.hash, this.pwd)
      .subscribe(
        result => {
          this.activeLink = result;
        },
        error => {
          this.activeLink = null;

          const value = error.json();

          if (value && value.message) {
            this.errorMessage = value.message;
          } else {
            this.errorMessage = error;
          }

          console.log(this.errorMessage);
        }
      );
  }

  onForgotSubmit() {
    this.forgotSubmited = true;
    this.errorForgotMessage = null;
    this.forgotMessage = null;

    this.auth.confirmEmail(this.forgotEmail)
      .subscribe(
        result => {
          if (result === true) {
            this.forgotMessage = 'Письмо со ссылкой для установки пароля отправлено по адресу: ' + this.forgotEmail;
          } else {
            this.errorForgotMessage = 'Произошла ошибка отправки письма';
          }
        },
        error => {
          this.forgotSubmited = false;
          const error1 = error.json();
          this.errorForgotMessage = error1 && error1.message ? error1.message : error1;
          console.log(error1);
        }
      );
  }

  onSubmit() {

    this.submited = true;
    this.errorMessage = null;
    this.success = false;

    this.auth.saveConfirm(this.id, this.time, this.hash, this.pwd, this.activeLink)
      .subscribe(
        result => {
          this.success = true;
        },
        error => {
          this.submited = false;

          this.success = false;

          const value = error.json();

          if (value && value.message) {
            this.errorMessage = value.message;
          } else {
            this.errorMessage = error;
          }

          console.log(this.errorMessage);
        }
      );
  }
}
