import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {ResultMessage} from '../model/form.model';
import {Observable} from 'rxjs';

@Injectable()
export class UploadService {

  private REQUEST_CONTINUE_UPLOAD_FORM_URL: string = '/file/upload/continue';

  constructor(private dataService: DataService) {
  }

  continueUploadForm(operatorId: number,
                     formId: number): Observable<ResultMessage> {

    return this.dataService.authPostData(this.REQUEST_CONTINUE_UPLOAD_FORM_URL,
      JSON.stringify({
        operatorId: operatorId,
        formId: formId
      }));

  }
}
