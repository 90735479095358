import {AbstractDataObject} from './common.model';

export class OrderField extends AbstractDataObject {
  public name: string;
  public sort: number;

  constructor(name: string, sort: number) {
    super();

    this.name = name;
    this.sort = sort;
  }
}

export class DraftPageFilter extends AbstractDataObject {
  public page: number = 1;
  public pageSize: number = 10;
  public formId: number;
  public begin: Date;
  public end: Date;

  constructor() {
    super();
  }
}

export class RequestDraftModel extends AbstractDataObject {
  public operatorId: number;
  public filter: DraftPageFilter;

  public orderDateSort: OrderField;
  public orderSubjectSort: OrderField;
  public orderFormSort: OrderField;
  public orderPeriodSort: OrderField;

  constructor() {
    super();
  }
}

export class FormFilterItem extends AbstractDataObject {
  public formId: number;
  public formName: string;

  constructor() {
    super();
  }
}

export class DraftItem extends AbstractDataObject {
  public updateTime: Date;
  public updateTimeStr: string;
  public name: string;
  public subjectName: string;
  public subjectCode: string;
  public periodicity: string;
  public quarter: number;
  public formId: number;
  public year: number;
  public month: number;
  public completionMonth: number;
  public completionDay: number;
  public completionDate: Date;
  public summary: boolean;

  constructor() {
    super();
  }
}

export class DraftModel extends AbstractDataObject {
  public total: number;
  public filter: DraftPageFilter;
  public dataList: DraftItem[] = [];
  public filterForms: FormFilterItem[] = [];

  public orderDateSort: OrderField = new OrderField('updateDate', 0);
  public orderSubjectSort: OrderField = new OrderField('subject', 0);
  public orderFormSort: OrderField = new OrderField('form', 0);
  public orderPeriodSort: OrderField = new OrderField('period', 0);

  constructor() {
    super();

    this.filter = new DraftPageFilter();
  }
}

