import {AfterViewInit, OnInit, Component} from '@angular/core';
import {Config} from '../../../config';
import {DataService} from '../../../service/data.service';
import {BranchListRequest, BranchListModel, BranchListItem} from '../../../model/formlist.model';
import {ActivatedRoute} from '@angular/router';
import {AppToastyService} from '../../../service/apptoasty.service';
import {FANCYBOX_COMMON_OPTIONS} from '../../../model/common.model';
import { timer } from 'rxjs/internal/observable/timer';

declare var $: JQueryStatic;

@Component({
  templateUrl: './branch-form-list.component.html',
  providers: [DataService, Config, AppToastyService]
})
export class BranchFormListComponent implements OnInit, AfterViewInit {
  private REST_SERVICE_BRANCH_LIST_GET = '/forms/branch-form-list';
  private REST_SERVICE_BRANCH_REFUSE_LIST_GET = '/forms/refuse-list';

  public formId: number;
  public month: number;
  public year: number;
  public quarter: number;

  public operatorId: number;

  public model: BranchListModel;

  public showClosed = false;

  constructor(private dataService: DataService, private route: ActivatedRoute, private toastyService: AppToastyService) {
    this.model = new BranchListModel();
  }

  ngOnInit(): void {
    this.formId = this.route.snapshot.params['id'];
    this.year = this.route.snapshot.params['year'];
    this.month = this.route.snapshot.params['month'];
    this.quarter = this.route.snapshot.params['quarter'];

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.operatorId) {
      this.operatorId = currentUser.operatorId;

      const request: BranchListRequest = new BranchListRequest();

      request.operatorId = currentUser.operatorId;
      request.formId = this.formId;
      request.month = this.month;
      request.year = this.year;

      this.dataService.authPostData(
        this.REST_SERVICE_BRANCH_LIST_GET, JSON.stringify(request))
        .subscribe(
          data => {
            if (data) {
              this.model = data;
              // console.log("Branch");
              console.log(this.model);
            } else {
              this.toastyService.addError(
                'Данные пользователя изменены администратором. ',
                'Просьба выйти из Личного Кабинета и авторизоваться заново'
              );
            }
          },
          error => {
            console.error(<any>error);
          }
        );
    } else {
      console.error('Operator ID not found');
    }
  }

  ngAfterViewInit(): void {

  }

  openSummary(item: BranchListModel) {
    this.model = item;

    const jQuery: any = $;
    let tagId = '';
    if ((item.sentCount + item.errorCount + item.workCount) > 0) {
      // формы отправленные, с ошибкой или в работе
      tagId = 'summary_warning';
    } else if (item.acceptedCount > 0) {
      // формы принятые
      tagId = 'summary_accepted';
    } else if ((item.refusedCount + item.requiredCount) > 0) {
      // Формы статуса отказ и надо сдать
      tagId = 'summary_workon';
    } else {
      window.location.href =
        '#/personal-area/form/'
        + item.formId + '/'
        + item.year + '/'
        + item.quarter + '/'
        + item.month + '/'
        + this.operatorId + '/1';
    }
    if (tagId !== '') {
      const dialog = jQuery.fancybox('#' + tagId, FANCYBOX_COMMON_OPTIONS);
    }
  }

  closeWarning() {
    const jQuery: any = $;
    jQuery.fancybox.close();
  }

  openSummaryReport(refuseFlag: boolean) {
    this.closeWarning();

    const request: BranchListRequest = new BranchListRequest();

    request.operatorId = this.operatorId;
    request.formId = this.model.formId;
    request.month = this.month;
    request.year = this.year;
    request.refuseFlag = refuseFlag;

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    request.userId = currentUser.id;

    this.dataService.authPostData(
      this.REST_SERVICE_BRANCH_REFUSE_LIST_GET, JSON.stringify(request))
      .subscribe(
        result => {
          if (result.success) {
            window.location.href = result.description;
            if (!refuseFlag) {
              const source = timer(2000);
              source.subscribe(() => {
                $('.blc_failure_surrender_form').slideDown(750);
                $('.btn_check, .btn_submit_form').prop('disabled', true).addClass('disabled');
                $(this).addClass('active');

                const container = $('.fsf');
                const scrollTo = $('.fsf_btn');
                container.scrollTop(
                  scrollTo.offset().top - container.offset().top + container.scrollTop()
                );
                $('#refuseFlag').val(1);
              });
            }
          } else {
            this.toastyService.addError('Сводная форма', result.description);
          }
        },
        error => {
          console.error(<any>error);
        }
      );
  }

  getItemClass(info: BranchListItem) {
    const classArray: string[] = [];

    const stringStatus: string = info.status.toString();

    switch (stringStatus) {
      case 'error'   :
        classArray.push('error_item');
        break;
      case 'accepted':
        classArray.push('adopted_item');
        break;
      case 'refused' :
        classArray.push('renouncement_item');
        break;
    }

    return classArray;
  }

  getListClass(model: BranchListModel) {
    const classArray: string[] = ['clearfix', 'global_table'];

    if (model.items.length === model.errorCount) {
      classArray.push('error');
    } else if (model.items.length === model.accepted) {
      classArray.push('adopted');
    }

    return classArray;
  }

  statusStr(status: string) {
    switch (status) {
      case  'required':
        return 'Надо сдать';
      case  'sent':
        return 'Отправлен';
      case  'accepted':
        return 'Принят';
      case  'error':
        return 'Ошибка данных';
      case  'refused':
        return 'Отказ';
      case  'work_on':
        return 'В работе';
      case  'rejected':
        return 'Отклонен';
      default:
        return '';
    }
  }

  subjectClosed(items: BranchListItem[]): boolean {
    return items.every(item => item.closed);
  }

  consolidatedClosed(model: BranchListModel): boolean {

    const consClosed = model.consolidated.closed;
    const allSubjectsClosed = model.items.every(subject => this.subjectClosed(subject.items));

    return consClosed && allSubjectsClosed;
  }

  openClosingInfo(closed: boolean) {

    if (closed) {
      return;
    }

    const jQuery: any = $;
    const dialog = jQuery.fancybox('#closing_info', FANCYBOX_COMMON_OPTIONS);
  }
}
