/**
 * Периодичность сдачи форм
 */
export enum Periodicity {
  /**
   * Годовая отчетность
   */
  yearly = 0,
    /**
     * Квартальная отчетность
     */
  quarterly = 1,
    /**
     * Месячная отчетность
     */
  monthly = 2
}

/**
 * Статусы формы
 */
export enum FormStatus {
  /**
   * Надо сдать
   */
  required = 0,
    /**
     * Отправлена на проверку
     */
  sent = 1,
    /**
     * Принята
     */
  accepted = 2,
    /**
     * Содержит ошибки или предупреждения
     */
  error = 3,
    /**
     * В работе
     */
  work_on = 4,
    /**
     * Отказана пользователем
     */
  refused = 5,
    /**
     * Отклонена
     */
  rejected = 6

}

export enum YearStatus {
  success = 0,
  error = 1,
  not_active = 2
}

export enum PasswordChangeLinkType {

  /** Первая установка пароля. */
  FIRST = 0,

    /** Смена пароля. */
  CHANGE = 1
}
