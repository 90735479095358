import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';

import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {RestClientService} from './rest-client.service';

@Injectable()
export class DataService {

  constructor(private restClient: RestClientService) {
  }

  getAuthDataByParams(path: string, mapParam: any): Observable<any> {
    const headers = this.restClient.getAuthRequestHeaders().append('Cache-Control', 'must-revalidate, no-store');

    return this.restClient
      .get({
        url: path,
        queryParams: mapParam,
        options: {
          killCache: true,
          headers: headers
        }
      })
      .pipe(
        map((responseData: any) => {
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handleError)
      );
  }

  getData(path: string): Observable<any> {
    return this.restClient
      .get({
        url: path
      })
      .pipe(
        map((responseData: any) => {
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handleError)
      );
  }

  authGetData(path: string): Observable<any> {
    return this.restClient
      .get({
        url: path,
        options: {
          authorization: true
        }
      })
      .pipe(
        map((responseData: any) => {
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handleError)
      );
  }

  postData(path: string, jsonData: string): Observable<any> {
    return this.restClient
      .post({
        url: path,
        body: jsonData,
        options: {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      })
      .pipe(
        map((responseData: any) => {
          // console.log("postData => responseData = ", responseData);
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handleError)
      )
  }

  postFullData(path: string, jsonData: string): Observable<any> {
    return this.restClient
      .post({
        url: path,
        body: jsonData,
        options: {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      })
      .pipe(
        map((responseData: any) => {
          // console.log("postData => postFullData = ", responseData);
          if (responseData) {
            return responseData;
          }
        }),
        catchError(DataService.handleError)
      )
  }

  authPostData(path: string, jsonData: string): Observable<any> {
    return this.restClient
      .post({
        url: path,
        body: jsonData,
        options: {
          authorization: true
        }
      })
      .pipe(
        map((responseData: any) => {
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handleError)
      )
  }

  changePassword(jsonData: string): Observable<any> {
    return this.restClient
      .post({
        url: '/security/replace',
        body: jsonData,
        options: {
          authorization: true
        }
      })
      .pipe(
        map((responseData: any) => {
          if (responseData) {
            return responseData.data;
          }
        }),
        catchError(DataService.handlePasswordChangeError)
      )
  }

  private static handlePasswordChangeError(error: Response | any) {
    let errMsg: string;

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(errMsg);
  }

  private static handleError(error: Response | any) {
    let errMsg: string;
    //console.log("error => " + error);
    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

      if (error.status === 403) {
        localStorage.removeItem('currentUser');
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    //console.log("errMsg => " + errMsg);

    return throwError(errMsg);
  }
}
