import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  templateUrl: './personal-area-main.component.html',
})
export class PersonalAreaMainComponent {
  router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  isActive(url): boolean {
    if (this.router.url && this.router.url.indexOf('/form/') > 0) {
      // Перешли в форму
      return url === localStorage.getItem('previousRoute');
    } else {
      return this.router.isActive(url, false);
    }
  }
}
