// <reference path="../../../../node_modules/es6-promise/es6-promise.d.ts"/>

import {Injectable} from '@angular/core';
// import {Http, Response} from "@angular/http";
// import {Config} from "../../config";
// Statics
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {MainFormData} from '../model/common.model';
import {RequiredFormList} from '../model/formlist.model';

@Injectable()
export class HomeFormService {
  private REST_SERVICE_TYPE_LIST = '/main/form-data';
  private REST_SERVICE_FORM_LIST = '/main/common-form-list';

  constructor(private dataService: DataService) {
  }

  getMainFormData(): Observable<MainFormData> {
    return this.dataService.postData(this.REST_SERVICE_TYPE_LIST, JSON.stringify({}));
  }

  getFormInfoList(serviceType: number, isPrimary: boolean): Observable<RequiredFormList> {
    return this.dataService.postData(this.REST_SERVICE_FORM_LIST,
      JSON.stringify({
        serviceType: serviceType,
        licNumber: "kuku",
        month: "11",
        year: "2020",
        isPrimary: isPrimary
      }));
  }
}
