import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cellValue'
})
export class CellValuePipe implements PipeTransform {

  private DECIMAL_COMMA_SEPARATOR = ',';
  private DECIMAL_DOT_SEPARATOR = '.';
  private THOUSANDS_SEPARATOR = ' ';
  private FRACTION_SIZE = 3;

  constructor() {
  }

  transform(value, fractionSize?: number, thousandsSeparator?: string, decimalSeparator?: string, paddingLength?: number): string {

    // console.log('transform: ' + value);

    if (!fractionSize) {
      fractionSize = this.FRACTION_SIZE;
    }

    if (!thousandsSeparator) {
      thousandsSeparator = this.THOUSANDS_SEPARATOR;
    }

    if (!decimalSeparator) {
      decimalSeparator = this.DECIMAL_COMMA_SEPARATOR;
    }

    const padding = new Array((paddingLength ? paddingLength : 10) + 1).join('0');
    // console.log(padding);

    let val = value + '';

    switch (decimalSeparator) {
      case this.DECIMAL_COMMA_SEPARATOR:
        val = val.replace(new RegExp('[' + this.DECIMAL_DOT_SEPARATOR + ']'), this.DECIMAL_COMMA_SEPARATOR);
        break;
      case this.DECIMAL_DOT_SEPARATOR:
        val = val.replace(new RegExp('[' + this.DECIMAL_COMMA_SEPARATOR + ']'), this.DECIMAL_DOT_SEPARATOR);
        break;
    }

    let [ integer, fraction = '' ] = (val || '').toString().split(decimalSeparator);

    const fractionInt = parseInt(fraction, 10);

    const integerNum = parseInt(integer, 10);

    if (integerNum > 0) {
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    } else {
      integer = '0';
    }

    if (isNaN(fractionInt) || fractionInt <= 0) {
      return integer;
    }

    if (fractionSize > 0) {
      return integer + decimalSeparator + (fraction + padding).substring(0, fractionSize)
    }

    // console.log('transform after: ' + result);
    return integer;
  }
}
