import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

// import {Observable} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  private jwtHelper: JwtHelperService;

  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate() {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (user && user.token && !this.jwtHelper.isTokenExpired(user.token)) {
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (user && user.token && !this.jwtHelper.isTokenExpired(user.token)) {
      return true;
    }

    this.router.navigate(['login']);

    return false;
  }

}
