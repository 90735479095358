import {PasswordChangeLinkType} from './enums.model';
export const DOUBLE_VALUE_REGEX_PATTERN = '[-]{0,1}\d{0,15}(,\d{0,4}){0,1}';

/**
 *   Параметры вызова диалога
 */
export const FANCYBOX_COMMON_OPTIONS = {
  fitToView: false,
  width: 'auto',
  height: 'auto',
  margin: [0, 0, 0, 0],
  autoSize: false,
  padding: [0, 0, 0, 0]
};


/**
 *   Общий класс для объектов модели данных
 */
export declare abstract class DataObject {
}

/**
 * Абстрактный класс объектов модели данных
 */
export class AbstractDataObject implements DataObject {

}

export class Month extends AbstractDataObject {
  public name: string;
  public num: number;

  constructor(num: number, name: string) {
    super();

    this.num = num;
    this.name = name;
  }
}

export class Year extends AbstractDataObject {
  public name: string;
  public num: number;

  constructor(num: number, name: string) {
    super();

    this.num = num;
    this.name = name;
  }
}

export class ServiceType extends AbstractDataObject {
  public name: string;
  public id: number;

  constructor(id: number, name: string) {
    super();

    this.id = id;
    this.name = name;
  }
}

export class OperatorProfileBranch extends AbstractDataObject {
  public name: string;
  public subjectCode: string;
  public closed: boolean;

  constructor() {
    super();
  }
}

export class OperatorProfile extends AbstractDataObject {
  public shortName: string;
  public fullName: string;
  public chiefName: string;
  public chiefEmail: string;
  public chiefPhone: string;
  public address: string;
  public zip: string;
  public inn: string;
  public ogrn: string;
  public okpo: string;
  public okved: string;
  public gvcCode: string;
  public mainFlag: boolean = false;
  public subjectCode: string;
  public branches: OperatorProfileBranch[] = [];

  constructor() {
    super();
  }
}

export class ProfileDataChange extends AbstractDataObject {
  public operatorOldPwd: string;
  public operatorNewPwd: string;
  public operatorReNewPwd: string;

  constructor() {
    super();
  }
}

export class MainFormData extends AbstractDataObject {
  public serviceTypes: ServiceType[] = [];
  public months: Month[] = [];
  public years: Year[] = [];

  constructor() {
    super();
  }
}

export class PasswordChangeLink extends AbstractDataObject {

  public id: number;
  public userId: number;
  public link: string;
  public createDate: Date;
  public dateOfUse: Date;
  public expirationDate: Date;
  public isActive: boolean;
  public linkType: PasswordChangeLinkType;

  constructor() {
    super();
  }
}
