import {AfterViewInit, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DataService} from "../../service/data.service";

@Component({
  templateUrl: './statistics.component.html',
  providers: [DataService]
})
export class StatisticComponent implements OnInit, AfterViewInit {

  public showpreloader: number;
  public statData: any;

  @ViewChildren('yearDataView') yearDataViews: QueryList<any>;

  constructor(private dataService: DataService) {
    this.showpreloader = 1;
  }

  ngOnInit(): void {
    this.dataService.getData("/data-publication/stat")
      .subscribe(
        data => {
          this.statData = data;
          this.showpreloader = 0;
        },
        error => {
          console.error(<any>error);
        }
      );
  }

  public getShortYear(year) {
    let s = "" + year;
    if (s.length == 4) {
      return s.substring(2);
    }
    return s;
  }

  ngAfterViewInit(): void {
    this.yearDataViews.changes.subscribe(t => {
      this.onYearDataRendered();
    });
  }

  private onYearDataRendered() {
    if (!this.statData) {
      return;
    }
    const slr_years = new Swiper('.swiper_slr_years',
      {
        autoplay: 0,
        speed: 1200,
        slidesPerView: 3,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        centeredSlides: true,
        preventClicks: false,
        controlInverse: true,
        initialSlide: this.statData.length - 1,
        onSlideChangeStart: function (event) {
          const index = $('.swiper_slr_years .swiper-wrapper .swiper-slide-active').attr('data-swiper-slide-index');
          $('.facts_slr_years').hide().removeClass('active');
          $('div[data-id=' + index + ']').show().addClass('active');
        }
      });

  }
}
